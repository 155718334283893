import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  Button,
  Link as MuiLink,
  Breadcrumbs,
  Box,
  IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Pagination,
  Avatar,
} from "@mui/material";
import Utils from "../../utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import Swal from "sweetalert2";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";
import Theme from "../../utils/Theme.utils";

const Reviewers = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isLogedIn = isAdmin_and_loged_in();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [search, setSearch] = useState<string>("");
  const [search_text, setSearchText] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let clickonActionButton = false;
      rows?.map((row, index) => {
        const actionButton = document.getElementById(`actionMenu-${index}`);
        if (e.target.contains(actionButton)) {
          clickonActionButton = true;
        }
      });
    });
  }, []);

  const getReviewers = (e?: any) => {
    e?.preventDefault();
    setLoadingdata(true);
    Services.Reviewer.GetReviewers(
      offset,
      limit,
      search_text?.toLocaleLowerCase()
    )
      .then((res) => {
        setLoadingdata(false);
        if (res.status) {
          res.data && setReviewers(res.data);
          setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoadingdata(false);
      });
  };

  useEffect(() => {
    isLogedIn && getReviewers();
  }, [offset, limit, search_text]);

  const resetPagenation = () => {
    setOffset(0);
    setTotal(0);
    setPage(1);
  };

  const handleSearch = (e: any) => {
    e?.preventDefault();
    resetPagenation();
    setSearchText(search);
  };

  const deleteReviewer = (id: number | string) => {
    Swal.fire({
      title: t("admins_page.db8l5i6xo"),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: t("admins_page.tgiikqtzt"),
      denyButtonText: t("admins_page.j0icdgpbo"),
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoadingdata(true);
        Services.Reviewer.DeleteReviewer(id)
          .then((res) => {
            setLoadingdata(false);
            Swal.fire(
              t("reviewers_page.wkc4qursk"),
              t("reviewers_page.bwgl52t1r")
            );
            setSearchText("");
            resetPagenation();
            getReviewers();
          })
          .catch((error) => {
            setLoadingdata(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message.split(".")[0],
              });
            }
          });
      }
    });
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("reviewers_page.qgox6t5oo")} />
      <div className=" ">
        <div className=" ">
          {/* list items */}
          <div className="flex flex-wrap justify-between items-center py-6 gap-2">
            <div className="flex flex-col justify-center items-start gap-2">
              <h3 className="head leading-6 font-bold text-gray-900">
                {t("reviewers_page.pdcpqvyv7")}
              </h3>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("reviewers_page.tkxyfewgo")}
                  </Link>
                  <Link to={location.pathname} className="hover:underline">
                    {t("reviewers_page.pdcpqvyv7")}
                  </Link>
                </Breadcrumbs>
              </div>
            </div>
            <div className="grow" />
            <form onSubmit={(e) => handleSearch(e)} className="w-full sm:w-fit">
              <PageComponents.SearchComponent
                search={search}
                setSearch={setSearch}
                classes="w-full sm:w-fit"
              />
            </form>
            <div className="w-full sm:w-fit">
              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate("/create-reviewer-account", {
                      state: { formType: "Create" },
                    })
                  }
                  fullWidth
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="CurrentColor"
                    viewBox="0 0 448 512"
                    className="w-4 h-4 mr-2"
                  >
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                  {t("reviewers_page.js2qufm8s")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* desktop responsive table */}
          <div className="">
            {!loadingdata && (
              <div className="hidden md:block w-full  overflow-x-auto">
                <Box
                  component={Paper}
                  sx={{
                    boxShadow:
                      "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                    borderRadius: "1rem",
                    margin: "0.5rem",
                  }}
                  className="overflow-x-auto "
                >
                  <ThemeProvider theme={Utils.Theme.TableTheme}>
                    <TableContainer className="muiTableContainer">
                      <Table aria-label="simple table">
                        <TableHead
                          sx={{
                            backgroundColor: "#e3e3e3",
                          }}
                        >
                          <TableRow>
                            <TableCell>
                              {t("reviewers_page.xt471357t")}
                            </TableCell>
                            <TableCell>
                              {t("reviewers_page.3jz8js9fa")}
                            </TableCell>
                            <TableCell>
                              {t("reviewers_page.h2dhd0jdy")}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowX: "clip", width: "100%" }}>
                          {reviewers?.length > 0 &&
                            reviewers?.map((item, index) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {item.id}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Avatar
                                    src={
                                      item.avatar &&
                                      `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                    }
                                    alt={item.name}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell align="right" className="relative">
                                  <IconButton
                                    onClick={(e) => {
                                      handleOpen(e);
                                      setSelectedItem(item);
                                    }}
                                    aria-describedby={`actionMenu-${index}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 512"
                                      className="o w-4 h-4"
                                    >
                                      <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                    </svg>
                                  </IconButton>
                                  <Popover
                                    id={`actionMenu-${index}`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    sx={{
                                      border: "none",
                                      boxShadow: "none",
                                      ".MuiPaper-root": {
                                        boxShadow:
                                          "inset 0px 6px 12px -4px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                                      },
                                    }}
                                  >
                                    <div
                                      className={`rounded shadow2  top-[25px] right-[40px] bg-white p-1.5 z-50 flex flex-col gap-1 justify-center items-center `}
                                      id={`actionMenu-${index}`}
                                    >
                                      <Button
                                        color="inherit"
                                        fullWidth
                                        onClick={() =>
                                          navigate("/edit-reviewer", {
                                            state: {
                                              formType: "Update",
                                              reviewer: selectedItem,
                                            },
                                          })
                                        }
                                      >
                                        <div className="flex justify-start items-center mr-auto pl-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-3 h-3"
                                            fill="currentColor"
                                            viewBox="0 0 512 512"
                                          >
                                            <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                          </svg>
                                          <span className="pl-4 capitalize ">
                                            {t("reviewers_page.w895a3dux")}
                                          </span>
                                        </div>
                                      </Button>
                                      <Button
                                        color="inherit"
                                        fullWidth
                                        onClick={() =>
                                          deleteReviewer(selectedItem?.id)
                                        }
                                      >
                                        <div className="flex justify-start items-center mr-auto pl-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            className="w-3 h-3 "
                                            fill="currentColor"
                                          >
                                            <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                          </svg>
                                          <span className="pl-4 capitalize ">
                                            {t("reviewers_page.1szep8wqm")}
                                          </span>
                                        </div>
                                      </Button>
                                    </div>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ThemeProvider>
                </Box>
              </div>
            )}
          </div>
          {/* mobile responsive data table */}
          <div className="py-3">
            <div className="flex flex-wrap h-full md:hidden">
              {!loadingdata &&
                reviewers?.length > 0 &&
                reviewers?.map((item, index) => (
                  <div className=" p-1 sm:p-2 w-full" key={index} id={item.id}>
                    <Accordion className="accordionShadow">
                      <AccordionSummary
                        expandIcon={<ExpandMoreRoundedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            textTransform: "uppercase",
                            fontWeight: "bolder",
                          }}
                        >
                          <p className="p0 font-medium capitalize overflow-wrap-anywhere">
                            {item.name}
                          </p>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <ThemeProvider theme={Utils.Theme.TableTheme}>
                            <Table>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("reviewers_page.xt471357t")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{item.id}</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("reviewers_page.3jz8js9fa")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  <Avatar
                                    src={
                                      item.avatar &&
                                      `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                    }
                                    alt={item.name}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("reviewers_page.h2dhd0jdy")}
                                  </TableCell>
                                </TableHead>
                                <TableCell className="uppercase">
                                  {item.name}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </ThemeProvider>
                          <div className="py-4 w-full flex">
                            <ThemeProvider
                              theme={Utils.Theme.MenuActiveButtonTheme}
                            >
                              <ButtonGroup
                                variant="contained"
                                aria-label="action button"
                                sx={{ margin: "auto" }}
                              >
                                <Button
                                  fullWidth
                                  onClick={() =>
                                    navigate("/edit-reviewer", {
                                      state: {
                                        formType: "Update",
                                        reviewer: item,
                                      },
                                    })
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-3 h-3"
                                    fill="currentColor"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                  </svg>
                                </Button>
                                <Button
                                  fullWidth
                                  onClick={() => deleteReviewer(item.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    className="w-3 h-3 "
                                    fill="currentColor"
                                  >
                                    <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                  </svg>
                                </Button>
                              </ButtonGroup>
                            </ThemeProvider>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
            </div>
          </div>
          {/* pagenation */}
          <div className="flex justify-end items-center">
            {!loadingdata && total > 0 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={Theme.MuiPagenationStyle}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviewers;
