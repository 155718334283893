import { useState, useEffect } from "react";
import {
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  ButtonGroup,
  Alert,
} from "@mui/material";
import Utils from "../../utils";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Input } from "@mui/joy";
import _ from "lodash";
import { IconButton } from "@mui/material";
import Services from "../../services";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import Swal from "sweetalert2";
import PageComponents from "../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const ListTimeScheduler = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  var now = new Date();
  const [date, setDate] = useState<string>("");
  const [noOfTimeSlots, setNoOfTimeSLots] = useState<number>(0);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [scheduler, setScheduler] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [scheduleUpdateIndex, setScheduleUpdateIndex] = useState<number>(0);
  const [formType, setFormType] = useState<string>("create");
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to reset time to midnight for date comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 (midnight)

  const formik = useFormik({
    initialValues: {
      date: "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.date()
        .required(t("add_new_time_schedule_dashboard_page.kh0zxxwy3"))
        .min(
          today, // Sets the minimum date to the current date
          t("add_new_time_schedule_dashboard_page.nqalaf2zb")
        )
        .max(
          new Date(now.setDate(now.getDate() + 31)),
          t("add_new_time_schedule_dashboard_page.j0ar7grv0")
        ),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  let checkSlotError = () => {
    let isErrorExist = false;
    timeSlots?.length > 0 &&
      timeSlots?.map((tSlot) => {
        if (tSlot.error) {
          isErrorExist = !isErrorExist;
          setErrorMessage(t("add_new_time_schedule_dashboard_page.yx2n2gpyf"));
        }
      });
    return isErrorExist;
  };

  useEffect(() => {
    setNoOfTimeSLots(
      !formik?.errors?.date && formik?.touched?.date && formik?.values?.date
        ? 1
        : 0
    );
    setTimeSlots([]);
  }, [formik?.values?.date, formik?.errors?.date, formik?.touched?.date]);

  const checkErrors = (isUsedForSave?: boolean) => {
    let errors = false;
    if (scheduler.length > 0) {
      let countErrors = 0;
      scheduler?.map((item: any) => {
        item["time_slots"]?.map((slot_item: any) => {
          if (slot_item["error"]) {
            errors = true;
          }
        });
      });
      errors
        ? setErrorMessage(t("add_new_time_schedule_dashboard_page.yx2n2gpyf"))
        : setErrorMessage("");
    } else {
      if (isUsedForSave) {
        setErrorMessage("");
      } else {
        document.getElementById("date")?.focus();
        setErrorMessage(t("add_new_time_schedule_dashboard_page.nx17ypyzi"));
        errors = true;
      }
    }

    return errors;
  };

  const handleSubmit = () => {
    const isErrorExist = checkErrors(true);
    if (isErrorExist) {
      return;
    }
    setLoading(true);
    const schedule_data = scheduler?.map((item: any) => {
      const updated_item = item["time_slots"]?.map((slot_item: any) => {
        return {
          start_time: slot_item["start_time"],
          end_time: slot_item["end_time"],
        };
      });
      return { date: item.date, time_slots: updated_item };
    });
    const data = {
      date_time_slots: schedule_data,
    };
    Services.Schedule.CreateSchedule(data)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          return navigate("/schedule");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message,
          });
        }
      });
  };

  const AddMoreExisitng = () => {
    const endedTimeSlot = document.getElementById(
      `endedTimeSlot-${noOfTimeSlots - 1}`
    ) as HTMLInputElement;
    const startedTimeSlot = document.getElementById(
      `startedTimeSlot-${noOfTimeSlots - 1}`
    ) as HTMLInputElement;
    const dateElement = document.getElementById("date");
    if (formik?.values?.date && !formik?.errors.date) {
      if (
        noOfTimeSlots === 0 ||
        (startedTimeSlot &&
          startedTimeSlot.value &&
          endedTimeSlot &&
          endedTimeSlot.value)
      ) {
        let isErrorExist = checkSlotError();
        if (isErrorExist) {
          setErrorMessage(t("add_new_time_schedule_dashboard_page.yx2n2gpyf"));
          document
            .getElementById(`startedTimeSlot-${noOfTimeSlots - 1}`)
            ?.focus();
        } else {
          noOfTimeSlots < 96 && setNoOfTimeSLots(noOfTimeSlots + 1);
          setErrorMessage("");
        }
      } else {
        startedTimeSlot && !startedTimeSlot.value
          ? startedTimeSlot?.focus()
          : !endedTimeSlot?.value && endedTimeSlot?.focus();
      }
    } else {
      return dateElement?.focus();
    }
  };

  const timeAlreadyExist = (slot: {
    start_time: string;
    end_time: string;
    error: string;
    index?: number;
  }) => {
    let t_slot = { ...slot };
    let st_slot = t_slot.start_time.split(":");
    let et_slot = t_slot.end_time.split(":");

    st_slot && st_slot[0] === "00" && (t_slot.start_time = "24:" + st_slot[1]);
    et_slot && et_slot[0] === "00" && (t_slot.end_time = "24:" + et_slot[1]);

    let error = false;
    timeSlots?.map((t, t_i) => {
      let ot = { ...t };
      let ost_slot = ot.start_time.split(":");
      let oet_slot = ot.end_time.split(":");

      ost_slot && ost_slot[0] === "00" && (ot.start_time = "24:" + ost_slot[1]);
      oet_slot && oet_slot[0] === "00" && (ot.end_time = "24:" + oet_slot[1]);

      //   if (
      //     t_slot["index"] !== t_i &&
      //     (t_slot["start_time"] == ot["start_time"] ||
      //       t_slot["end_time"] == ot["end_time"] ||
      //       t_slot["start_time"] == ot["end_time"] ||
      //       t_slot["end_time"] == ot["start_time"] ||
      //       (t_slot["start_time"] >= ot["start_time"] &&
      //         t_slot["start_time"] <= ot["end_time"]) ||
      //       (t_slot["end_time"] >= ot["start_time"] &&
      //         t_slot["start_time"] <= ot["end_time"]))
      //   ) {
      //     error = true;
      //   }

      if (
        (t_slot["index"] != t_i &&
          (t_slot["start_time"] == ot["start_time"] ||
            t_slot["end_time"] == ot["end_time"])) ||
        (t_slot["start_time"] > ot["start_time"] &&
          t_slot["start_time"] < ot["end_time"]) ||
        (t_slot["end_time"] < ot["end_time"] &&
          t_slot["end_time"] > ot["start_time"]) ||
        (t_slot["start_time"] < ot["start_time"] &&
          t_slot["end_time"] > ot["end_time"])
      ) {
        error = true;
      }
    });
    return error;
  };

  const handleChangeValue = (
    value: string,
    id: string,
    index: number,
    rangeType: string
  ) => {
    let time_slots: any[] = [...timeSlots];
    // function that will check time slots exist or not
    let timeSlotExist = false;
    let slot = { start_time: "", end_time: "", error: "" };
    time_slots?.length > 0 &&
      time_slots?.map((item: any, i: number) => {
        if (i === index) {
          timeSlotExist = true;
          slot = item;
        }
      });
    timeSlotExist
      ? (time_slots[index][rangeType] = value)
      : (slot = {
          start_time: rangeType === "start_time" ? value : "",
          end_time: rangeType === "end_time" ? value : "",
          error: "",
        });

    slot.error = timeAlreadyExist({ ...slot, index })
      ? t("add_new_time_schedule_dashboard_page.2ntf3xnfw")
      : "";
    slot.error !== t("add_new_time_schedule_dashboard_page.2ntf3xnfw") &&
      (slot.error = timeComparison(value, rangeType, index, slot));
    setTimeSlots(timeSlotExist ? time_slots : [...timeSlots, slot]);
  };

  const resetForms = () => {
    setDate("");
    setNoOfTimeSLots(0);
    setTimeSlots([]);
    setScheduleUpdateIndex(0);
    setFormType("create");
  };

  const saveTimeSlots = () => {
    // user can create maximum of 15 days schedule
    let isErrorExist = checkErrors(true);
    !isErrorExist && (isErrorExist = checkSlotError());
    if (isErrorExist) {
      return;
    }
    if (date && timeSlots?.length > 0 && scheduler?.length < 15) {
      let countErrors = 0;
      let firstErrorIndex = 0;
      timeSlots?.map((slot, index) => {
        if (slot.error || !slot.start_time || !slot.end_time) {
          ++countErrors;
          firstErrorIndex !== 0 && (firstErrorIndex = index);
        }
        if (!slot.start_time || !slot.end_time) {
          setErrorMessage(t("add_new_time_schedule_dashboard_page.qxhaitixg"));
        }
      });
      countErrors === 0 &&
        scheduler?.length > 0 &&
        scheduler?.map((item, index) => {
          if (item?.date == date) {
            ++countErrors;
            firstErrorIndex = index;
            setErrorMessage(
              t("add_new_time_schedule_dashboard_page.hs3l0ljk6")
            );
          }
        });
      if (countErrors === 0) {
        setScheduler((old) => {
          return [...old, { date: date, time_slots: timeSlots }];
        });
        resetForms();
      } else {
        document.getElementById(`startedTimeSlot-${firstErrorIndex}`)?.focus();
      }
    } else {
      return setErrorMessage(
        t("add_new_time_schedule_dashboard_page.qxhaitixg")
      );
    }
  };

  const delete_a_slot = (index: number) => {
    let slots_of_time: string[] = [];
    timeSlots?.length > 0 &&
      timeSlots?.map((item: string) => {
        slots_of_time.push(item);
      });
    slots_of_time?.splice(index, 1);
    setTimeSlots(slots_of_time);
    setNoOfTimeSLots(noOfTimeSlots - 1);
  };

  const removeSchedule = (index: number) => {
    const schedulesArray: any[] = [];
    scheduler?.length > 0 &&
      scheduler?.map((item: any, i: number) => {
        schedulesArray.push(item);
      });
    schedulesArray.splice(index, 1);
    setScheduler(schedulesArray);
    setFormType("create");
  };

  const editSchedule = (schedule: any, index: number) => {
    setDate(schedule.date);
    setTimeSlots(schedule.time_slots);
    setNoOfTimeSLots(schedule.time_slots.length);
    setScheduleUpdateIndex(index);
    setFormType("update");
  };

  const updateTimeSlots = () => {
    let isErrorExist = checkErrors();
    !isErrorExist && (isErrorExist = checkSlotError());
    if (isErrorExist) {
      return;
    }
    const newState = scheduler?.map((schedule, index) => {
      // 👇️ if id equals 2, update country property
      if (index === scheduleUpdateIndex) {
        return { date, time_slots: timeSlots };
      }
      // 👇️ otherwise return the object as is
      return schedule;
    });
    setScheduler(newState);
    resetForms();
  };

  const handleEndInputDisabled = (index: number) => {
    if (
      timeSlots.length === 0 ||
      (timeSlots.length > 0 && timeSlots[index]?.start_time === "")
    ) {
      return true;
    }
    return false;
  };

  const timeComparison = (
    time: string,
    comparsionType: string,
    index: number,
    existTimeSlot: any
  ) => {
    const Time = time.split(":");
    let isValidTimeSlot = false;
    let error = existTimeSlot.error ? existTimeSlot.error : "";
    timeSlots?.length > 0 &&
      timeSlots?.map((slot, i) => {
        if (index === i) {
          const tempTime =
            comparsionType === "start_time"
              ? Time
              : slot["start_time"].split(":");
          const timeSlot =
            comparsionType === "start_time"
              ? slot["end_time"].split(":")
              : Time;

          timeSlot[0] && timeSlot[0] === "00" && (timeSlot[0] = "24");
          tempTime[0] && tempTime[0] === "00" && (tempTime[0] = "24");

          const hourlyDifference = timeSlot[0] - tempTime[0];
          const differenceInMinutes = timeSlot[1] - tempTime[1];
          const totalDifference = hourlyDifference * 60 + differenceInMinutes;
          if (totalDifference < 15) {
            error = t("add_new_time_schedule_dashboard_page.5qqdywnuk");
          } else if (totalDifference > 60) {
            error = t("add_new_time_schedule_dashboard_page.5chjg9uyf");
          } else {
            isValidTimeSlot = true;
            error = "";
          }
        }
      });
    return error;
  };

  const handleInputValue = (field: string, index: number) => {
    let value = "";
    if (timeSlots.length > 0) {
      timeSlots.filter((item, i) => {
        if (i === index) {
          value = item[field];
        }
      });
    }
    return value;
  };

  return (
    <>
      <PageComponents.MetaComponent
        title={t("add_new_time_schedule_dashboard_page.60j955q2y")}
      />
      {loading && <PreLoaderComponent2 />}
      <div className="">
        <div className=" ">
          <div className="relative">
            {/* Heading */}
            <div className="flex justify-between items-center py-6">
              <div className="flex flex-col justify-center items-start gap-2">
                <h3 className="head leading-6 font-bold">
                  {t("add_new_time_schedule_dashboard_page.lgmnhn1p6")}
                </h3>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("add_new_time_schedule_dashboard_page.rxqvmb7b8")}
                    </Link>
                    <Link to={`/schedule`} className="hover:underline">
                      {t("time_schedule_dashboard_page.47tas5py8")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("add_new_time_schedule_dashboard_page.lgmnhn1p6")}
                    </Link>
                  </Breadcrumbs>
                </div>
              </div>
              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                <Button variant="contained" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-2"
                    viewBox="0 0 448 512"
                    fill="CurrentColor"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                  {t("add_new_time_schedule_dashboard_page.2fsz4a3gv")}
                </Button>
              </ThemeProvider>
            </div>
            <form onSubmit={formik?.handleSubmit}>
              {/* Schedule Auction Time */}
              <div className="m-auto w-full overflow-x-auto">
                <Box
                  component={Paper}
                  sx={{
                    boxShadow:
                      "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                    borderRadius: "1rem",
                    margin: "0.5rem",
                  }}
                  className="overflow-x-auto "
                >
                  <div className="px-4 py-4">
                    {errorMessage && (
                      <Alert severity="error" sx={{ marginBottom: "10px" }}>
                        <p className="p1">{errorMessage}</p>
                      </Alert>
                    )}
                    <div className="flex flex-col gap-2  ">
                      <div className="InputFieldSetting py-2">
                        <label className="p1 px-1" htmlFor="date">
                          {t("add_new_time_schedule_dashboard_page.oqr43ygaf")}
                          <sup className="requireField">*</sup>
                        </label>
                        <Input
                          name="date"
                          type="date"
                          id="date"
                          onChange={(e: any) => {
                            formik?.handleChange(e);
                            setDate(e.target.value);
                            noOfTimeSlots == 0 && setNoOfTimeSLots(1);
                          }}
                          value={date}
                          sx={
                            formik?.touched?.date && formik?.errors?.date
                              ? Utils.Theme.JoyInputError
                              : Utils.Theme.JoyGrayShadeInputStyle2
                          }
                          onBlur={formik?.handleBlur}
                        />
                        <WarningAlert
                          message={
                            formik?.touched?.date ? formik?.errors?.date : ""
                          }
                        />
                      </div>
                      <div
                        className={`InputFieldSetting ${
                          noOfTimeSlots ? "py-2" : ""
                        }`}
                      >
                        {noOfTimeSlots > 0 && (
                          <label className="p1 px-1" htmlFor="timeSlot">
                            {t(
                              "add_new_time_schedule_dashboard_page.yeaul8x87"
                            )}
                            <sup className="requireField">*</sup>
                          </label>
                        )}
                        {_.times(noOfTimeSlots, (index: number) => (
                          <div key={index}>
                            <div className="flex justify-between items-center gap-2 sm:gap-4">
                              <div className="w-full">
                                <PageComponents.TimeMask
                                  name="startedTimeSlot"
                                  id={`startedTimeSlot-${index}`}
                                  t={handleInputValue("start_time", index)}
                                  setT={(time: string) => {
                                    handleChangeValue(
                                      time,
                                      `startedTimeSlot-${index}`,
                                      index,
                                      "start_time"
                                    );
                                  }}
                                  formType={formType}
                                  isDisabled={false}
                                />
                              </div>
                              <div className="w-full">
                                <PageComponents.TimeMask
                                  name="endedTimeSlot"
                                  id={`endedTimeSlot-${index}`}
                                  setT={(time: string) => {
                                    handleChangeValue(
                                      time,
                                      `endedTimeSlot-${index}`,
                                      index,
                                      "end_time"
                                    );
                                  }}
                                  t={handleInputValue("end_time", index)}
                                  isDisabled={
                                    handleEndInputDisabled(index) ? true : false
                                  }
                                  formType={formType}
                                />
                              </div>
                              <IconButton
                                className=""
                                onClick={() => delete_a_slot(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3.5 sm:w-4 m-auto"
                                  fill="currentColor"
                                  viewBox="0 0 384 512"
                                >
                                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                </svg>
                              </IconButton>
                            </div>
                            {timeSlots[index]?.error && (
                              <WarningAlert message={timeSlots[index]?.error} />
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
                        <div className="flex justify-start items-center gap-2 w-full sm:w-fit">
                          <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                            <ButtonGroup
                              variant="text"
                              aria-label="outlined primary button group"
                              sx={{
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 1px 6px 0px",
                              }}
                              fullWidth
                            >
                              <Button
                                variant="text"
                                onClick={AddMoreExisitng}
                                fullWidth
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-4 h-4 mr-2"
                                  viewBox="0 0 448 512"
                                  fill="CurrentColor"
                                >
                                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                </svg>
                                <span className="min-w-max pr-2 p1">
                                  {t(
                                    "add_new_time_schedule_dashboard_page.tstkc8zmx"
                                  )}
                                </span>
                              </Button>
                              <Button
                                variant="text"
                                onClick={() =>
                                  formik?.errors?.date || !formik?.values.date
                                    ? document.getElementById("date")?.focus()
                                    : formType === "create"
                                      ? saveTimeSlots()
                                      : updateTimeSlots()
                                }
                              >
                                {formType === "create"
                                  ? t(
                                      "add_new_time_schedule_dashboard_page.r4fvh0735"
                                    )
                                  : t(
                                      "add_new_time_schedule_dashboard_page.bl2bhhuzy"
                                    )}
                              </Button>
                            </ButtonGroup>
                          </ThemeProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className=" px-6 py-4">
                {/* Scheduler */}
                {scheduler?.length > 0 && (
                  <>
                    <h3 className="py-4 font-bold head text-center sm:text-left ">
                      {t("add_new_time_schedule_dashboard_page.atsxczgg4")}
                    </h3>
                    <div className="flex justify-center sm:justify-start items-start w-full sm:w-fit flex-wrap gap-8 py-5">
                      {scheduler?.map((schedule: any, index: number) => (
                        <div key={index} className="w-full sm:w-fit">
                          <Box
                            component={Paper}
                            sx={{
                              position: "relative",
                              boxShadow:
                                "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                              borderRadius: "1rem",
                            }}
                          >
                            <div className="absolute top-[-10px] right-[-10px] shadow-md hover:shadow-lg rounded-full bg-white ">
                              <IconButton onClick={() => removeSchedule(index)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3 m-auto"
                                  fill="currentColor"
                                  viewBox="0 0 384 512"
                                >
                                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                </svg>
                              </IconButton>
                            </div>
                            <div className="absolute top-[-10px] left-[-10px] shadow-md hover:shadow-lg rounded-full bg-white ">
                              <IconButton
                                onClick={() => editSchedule(schedule, index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3 m-auto"
                                  fill="currentColor"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                </svg>
                              </IconButton>
                            </div>
                            <div className="px-6 py-4 text-center sm:text-left">
                              <h4 className="textColor font-bold p0 pb-1">
                                {schedule.date}
                              </h4>
                              {schedule?.time_slots?.length > 0 &&
                                schedule?.time_slots?.map(
                                  (item: any, index: number) => (
                                    <p key={index} className="p1">
                                      {item.start_time} - {item.end_time}
                                    </p>
                                  )
                                )}
                            </div>
                          </Box>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className="w-full  px-6 py-4 flex justify-end items-center ">
                <div className="flex justify-end items-center w-full sm:w-fit">
                  <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 1px 6px 0px",
                      }}
                      fullWidth
                    >
                      {t("add_new_time_schedule_dashboard_page.kxdw5j47t")}
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTimeScheduler;
