import { useState, memo } from "react";
import { SearchTypeProps } from "../../type/Auction.type";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../utils";
import { Button, Grid, ThemeProvider, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from "./CircleIcon.component";

// checked
const SearchCardComponent = memo(
  ({
    getSavedSearch,
    item,
    searchedText,
    deleteSearch,
    savedSearches,
    index,
  }: SearchTypeProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const isAutionEnd = (data: any) => {
      if (
        data?.auction?.status === "live" &&
        Utils.CalculateDays(data?.auction?.auction_end) > 0
      ) {
        return false;
      }
      return true;
    };

    return (
      <>
        {item?.listing ? (
          <div
            key={item?.listing?.uuid}
            className="h-full relative w-full sm:max-w-[500px] flex flex-col mt-2 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl border border-gray-200 px-4 py-7 gap-2 hovershadow"
          >
            <div className="flex flex-row items-center justify-between ">
              <Link to={`/search/${searchedText}`}>
                <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                  <Button variant="contained" size="small">
                    {searchedText}
                  </Button>
                </ThemeProvider>
              </Link>
              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => deleteSearch(searchedText)}
                >
                  {t("search_card_component.wr5hltytx")}
                </Button>
              </ThemeProvider>
            </div>
            <div className="w-full">
              {!isAutionEnd(item?.listing) &&
                item?.listing?.has_reserve_price === false && (
                  <span className="inline-block whitespace-nowrap rounded-full bg-black px-4 py-1.5 text-center align-baseline p1 font-semibold leading-none text-yellow-400 w-full">
                    {t("search_card_component.6tyivmleb")}
                  </span>
                )}
            </div>
            <div className="py-2">
              <div className="flex flex-wrap justify-between">
                <div className="truncate w-5/6">
                  <Link
                    to={`/auctions/${
                      item?.listing?.year?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.make?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.model?.replace(/\s+/g, "-") +
                      (item?.listing?.title
                        ? "-" + item?.listing?.title?.replace(/\s+/g, "-")
                        : "")
                    }`}
                    className="font-bold head cursor-pointer"
                    state={{ uuid: item?.listing?.uuid }}
                  >
                    {item?.listing?.title ? item?.listing?.title : ""}ssss
                  </Link>
                </div>
                <button
                  className={`textColor flex-col gap-1 cursor-pointer ${
                    item?.listing?.watching ? "" : "displayONHover"
                  }  transition-all dhrOnhover`}
                  disabled={loading}
                >
                  {item?.listing?.watching ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 "
                      viewBox="0 0 576 512"
                    >
                      <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                    </svg>
                  )}
                  <hr className="bgColor rounded p-[1px] shadow-sm" />
                </button>
              </div>
              <div className="text-gray-500 p0 truncate flex justify-start items-center gap-2 capitalize">
                {item?.listing?.make}
                <CircleIcon className="w-1 h-1 text-gray-500" />
                {item?.listing?.model + " " + item?.listing?.year}
                <CircleIcon className="w-1 h-1 text-gray-500" />
                {item?.listing?.mileage}
                {" KM"}
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
                <div className="flex justify-center">
                  <Link
                    to={`/auctions/${
                      item?.listing?.year?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.make?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.model?.replace(/\s+/g, "-") +
                      (item?.listing?.title
                        ? "-" + item?.listing?.title?.replace(/\s+/g, "-")
                        : "")
                    }`}
                    state={{ uuid: item?.listing?.uuid }}
                    className="w-full"
                  >
                    <img
                      src={`${process.env.REACT_APP_HOST_API_KEY}/${item?.listing?.photos[0]?.uri}`}
                      alt={
                        item?.listing?.year +
                        " " +
                        item?.listing?.make +
                        " " +
                        item?.listing?.model +
                        (item?.listing?.title ? " " + item?.listing?.title : "")
                      }
                      className="cardh"
                      loading="lazy"
                    />
                  </Link>
                </div>
                <Grid
                  container
                  spacing={0}
                  className="rounded-b-xl p-0 h-fu ll"
                >
                  {!isAutionEnd(item?.listing) && (
                    <Grid
                      item
                      xs={5.95}
                      className="text-center bg-yellow-300 p-0"
                    >
                      <div className={`font-bold text-black text-lg`}>
                        {item?.listing?.auction?.auction_end &&
                        Utils.CalculateDays(
                          item?.listing?.auction?.auction_end
                        ) > 1 ? (
                          Utils.CalculateDays(
                            item?.listing?.auction?.auction_end
                          ).toFixed(0) +
                          " " +
                          (Utils.CalculateDays(
                            item?.listing?.auction?.auction_end
                          ) > 1
                            ? t("search_card_component.9il748cza")
                            : t("search_card_component.9il748czb"))
                        ) : (
                          <Countdown
                            date={item?.listing?.auction?.auction_end} daysInHours={true}
                          />
                        )}
                      </div>

                      <div className="text-gray-500 text-sm">
                        {t("search_card_component.mpxu3n6vm")}
                      </div>
                    </Grid>
                  )}
                  {!isAutionEnd(item?.listing) && <Grid item xs={0.1} />}
                  <Grid
                    item
                    xs={!isAutionEnd(item?.listing) ? 5.95 : 12}
                    className="text-center bg-yellow-300 p-0"
                  >
                    <div className="font-bold text-black text-lg">
                      {item?.listing?.current_bid?.price
                        ? item?.listing?.current_bid?.price
                        : 0}{" "}
                      CHF
                    </div>
                    <div className="text-gray-500 text-sm">
                      {item?.listing?.bids_count
                        ? item?.listing?.bids_count
                        : 0}{" "}
                      {t("search_card_component.qne6lw958")}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center truncate">
                <div className="p1 font-semibold">
                  {t("search_card_component.4byermfwk")}
                </div>
                <div className="p1 font-bold">{item?.listing?.body_style}</div>
              </div>
              <div className="flex justify-between items-center truncate">
                <div className="p1 font-semibold">
                  {t("search_card_component.pktesvg6e")}
                </div>
                <div className="p1 font-bold">{item?.listing?.engine}</div>
              </div>
              <div className="flex justify-between items-center truncate">
                <div className="p1 font-semibold">
                  {t("search_card_component.4xge3isgg")}
                </div>
                <div className="p1 font-bold">
                  {item?.listing?.transmission}
                </div>
              </div>
              <div className="flex justify-between items-center truncate">
                <div className="p1 font-semibold">
                  {t("search_card_component.ycgjuftog")}
                </div>
                <div className="p1 font-bold">
                  <div className="flex -space-x-2 items-center justify-center">
                    <Tooltip
                      title={
                        t("details_submission_page.2xwpepu29") +
                        " : " +
                        item?.listing?.interior_color
                      }
                    >
                      <span
                        className={`w-6 h-6 border-2 border-black rounded-full drop-shadow-sm hovershadow z-[1] hover:z-[999] hover:drop-shadow-md interiorHover`}
                        style={{
                          background: item?.listing?.interior_color
                            ? item?.listing?.interior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor = item?.listing
                            ?.interior_color
                            ? item?.listing?.interior_color
                            : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                    <Tooltip
                      title={
                        t("details_submission_page.e5gzqci3g") +
                        " : " +
                        item?.listing?.exterior_color
                      }
                    >
                      <span
                        className={`w-6 h-6 border-2 border-black rounded-full drop-shadow-sm hovershadow hover:z-[999] z-[2] hover:drop-shadow-md exteriorHover`}
                        style={{
                          background: item?.listing?.exterior_color
                            ? item?.listing?.exterior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor = item?.listing
                            ?.exterior_color
                            ? item?.listing?.exterior_color
                            : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center truncate">
                <div className="p1 font-semibold">
                  {t("search_card_component.6mfla5mjt")}
                </div>
                <div className="p1 font-bold">
                  {item?.listing?.zip_code || ""}{" "}
                  {item?.listing?.city_and_province || ""}{" "}
                  {item?.listing?.country || ""}
                </div>
              </div>
            </div>
            <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
              <Button
                variant="text"
                type="button"
                onClick={() => navigate(`/search/${searchedText}`)}
              >
                +{savedSearches[searchedText]?.total}{" "}
                {t("search_card_component.6vxiseoks")}
              </Button>
            </ThemeProvider>
          </div>
        ) : (
          <div
            key={index}
            className="h-full relative w-full sm:max-w-[500px] flex flex-col mt-2 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl border border-gray-200 px-4 py-7 gap-6 hovershadow justify-between"
          >
            <div className="flex flex-col gap-6">
              <div className="flex flex-row items-center justify-between pb-1">
                <Link to={`/search/${searchedText}`}>
                  <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                    <Button variant="contained" size="small">
                      {searchedText}
                    </Button>
                  </ThemeProvider>
                </Link>
                <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => deleteSearch(searchedText)}
                  >
                    {t("search_card_component.wr5hltytx")}
                  </Button>
                </ThemeProvider>
              </div>
              <div className="auction-top-img cursor-pointer  h-[300px] bg-[var(--mid-gray-color)] flex justify-center items-center capitalize tracking-wide rounded-xl shadow  w-full">
                <p className="head font-medium ">
                  {t("search_card_component.u4kg5po5j")}
                </p>{" "}
              </div>
            </div>
            <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
              <Button
                variant="text"
                type="button"
                onClick={() => navigate(`/search/${searchedText}`)}
              >
                +{savedSearches[searchedText]?.total}{" "}
                {t("search_card_component.6vxiseoks")}
              </Button>
            </ThemeProvider>
          </div>
        )}
      </>
    );
  }
);

export default SearchCardComponent;
