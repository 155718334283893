import { ApiConfig, FormapiConfig } from "../config";
import Auth from "./Auth.service";

const MyListing = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/account/listings?offset=${offset}&limit=${limit}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetListings = (
  offset: number,
  limit: number,
  fetch: string,
  sort?: string | null,
  startYear?: number | null,
  endYear?: number | null,
  transmission?: string | null,
  search?: string | null,
  bodyStyle?: string | null,
  status?: string | null
) => {
  return ApiConfig.get("api/user/listings", {
    params: {
      offset,
      limit,
      fetch,
      sort,
      start_year: startYear,
      end_year: endYear,
      transmission,
      body_style: bodyStyle,
      search,
      status,
    },
  })
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetList = (id: number) => {
  return ApiConfig.get(`/api/user/listings/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const AddLisitng = (data: any) => {
  return FormapiConfig.post(`/api/user/listings`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const UpdateLisitng = (id: string | number, data: any) => {
  let API = ApiConfig;
  if (data.photos) {
    API = FormapiConfig;
  }
  return API.post(`/api/user/listings/${id}/update`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const ContactSeller = (id: string | number, data: any) => {
  let API = ApiConfig;
  if (data.photos) {
    API = FormapiConfig;
  }
  return API.post(`/api/user/listings/${id}/contact-seller`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const UpdateAdminLisitng = (id: string | number, data: any) => {
  return ApiConfig.post(`/api/admin/listings/${id}`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetAllListings = (
  offset: number,
  limit: number,
  sort?: string | null,
  search?: string | null,
  application_status?: string | null,
  details_submission_status?: string | null,
  has_scheduled_meeting?: boolean | null
) => {
  return ApiConfig.get("api/admin/listings", {
    params: {
      offset,
      limit,
      sort,
      search,
      application_status,
      has_scheduled_meeting,
      details_submission_status,
    },
  })
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetOneListings = (id: number) => {
  return ApiConfig.get(`api/admin/listings/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetOneListingForUser = (id: string | number) => {
  return ApiConfig.get(`api/user/account/listings/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetBidHistoryOfUserListings = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/user/account/listings/${id}/bids?limit=${limit}&offset=${offset}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetBidHistoryOfListingForAdmin = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/admin/listings/${id}/bids?limit=${limit}&offset=${offset}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetQuestionsOfUserListings = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/user/account/listings/${id}/questions?limit=${limit}&offset=${offset}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetQuestionsOfListingForAdmin = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/admin/listings/${id}/questions?limit=${limit}&offset=${offset}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetCommentsOfUserListings = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/user/account/listings/${id}/comments?limit=${limit}&offset=${offset}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetCommentsOfListingForAdmin = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/admin/listings/${id}/comments?limit=${limit}&offset=${offset}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const ChangeApplicationStatus = (id: string | number, data: any) => {
  return ApiConfig.post(
    `api/admin/listings/${id}/change-application-status`,
    data
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const ChangeDetailsStatus = (id: string | number, data: any) => {
  return ApiConfig.post(
    `api/admin/listings/${id}/approve-or-reject-details`,
    data
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const GetAdminListInfo = (id: string | number) => {
  return ApiConfig.get(`/api/admin/listings/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const DeleteListingPhoto = (id: string | number, data: any) => {
  return ApiConfig.post(`/api/user/listings/${id}/photos/delete`, data).then(
    (res: any) => {
      return res.data;
    }
  ).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const SaveListingReview = (data: any) => {
  return ApiConfig.post(`api/user/system/rating-reviews`, data).then(
    (res: any) => {
      return res.data;
    }
  ).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const Listing = {
  SaveListingReview,
  DeleteListingPhoto,
  GetAdminListInfo,
  UpdateAdminLisitng,
  ChangeDetailsStatus,
  ChangeApplicationStatus,
  UpdateLisitng,
  GetCommentsOfUserListings,
  GetQuestionsOfUserListings,
  GetBidHistoryOfUserListings,
  GetOneListingForUser,
  GetOneListings,
  GetAllListings,
  AddLisitng,
  MyListing,
  GetListings,
  GetList,
  GetBidHistoryOfListingForAdmin,
  GetQuestionsOfListingForAdmin,
  GetCommentsOfListingForAdmin,
  ContactSeller,
};

export default Listing;
