import React, { useState, useEffect, memo, lazy, Suspense } from "react";
import Countdown from "react-countdown";
import { Images } from "../../assets";
import PageComponents from "../components";
import ShareModal from "../../components/ShareDailog.component";
import Services from "../../services";
import { useParams } from "react-router-dom";
import { PreLoaderComponent, PreLoaderComponent2 } from "../../components";
import moment from "moment";
import AuctionDetail from "./AuctionDetail.component";
import Utils from "../../utils";
import { Alert, Button } from "@mui/material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ImagesGallery from "./ImagesGallery";
import { useTranslation } from "react-i18next";

const CarVideos = lazy(() => import("../components/CarVideos.component"));
const CarShowCase = lazy(() => import("./CarShowCase.component.page"));
const ContactComponent = lazy(() => import("../components/Contact.component"));

const ApplicationAuctionInfo = memo(({ setAuctionInfo, data }: any) => {
  const { t } = useTranslation();
  const role = Services.Storage.getCurrentRole();
  const params = useParams();
  const [showCars, setShowCars] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [auction, setAuction] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [auction_images, setAuctionImages] = useState<any[]>([]);
  const [totalBids, setTotalBids] = useState<number>(0);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [auctionEnded, setAuctionEnded] = useState<boolean>(false);
  const [showContact, setShowContact] = useState<boolean>(false);
  const [sliderIndex, setSliderIndex] = useState<number | null>(null);
  const [isAuctionEnded, setIsAuctionEnded] = useState<boolean>(false);

  useEffect(() => {
    setAuctionEnded(
      auction?.auction
        ? auction?.auction?.status !== "live"
          ? true
          : Utils.CalculateDays(auction?.auction?.auction_end) <= 0
        : false
    );
  }, [
    Utils.CalculateDays(auction?.auction?.auction_end) > 0,
    auction?.auction?.status,
  ]);

  useEffect(() => {
    Utils.CalculateDays(auction?.auction?.auction_end) < 0 &&
      setIsAuctionEnded(!isAuctionEnded);
  }, [auction?.auction?.auction_end]);

  const showShareModal = () => {
    setIsModalOpen((open) => !open);
  };

  const getListing = () => {
    if (params.list_id) {
      setLoading(true);

      let apiUrl;
      if (role !== "user") {
        apiUrl = Services.Listing.GetAdminListInfo(params.list_id);
      } else {
        apiUrl = Services.Listing.GetOneListingForUser(params.list_id);
      }
      apiUrl
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          res.status && setAuction(res.data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    setAuctionInfo(auction);
  }, [auction]);

  const handleCarImages = (photos: any) => {
    let array: any[] = [];
    Object.keys(photos)?.length > 0 &&
      Object.keys(photos)?.map((photoCategory: string) => {
        let photo = photos[photoCategory];
        photo.map((item: string) => {
          array.push(item);
        });
      });

    setAuctionImages(array);
  };

  useEffect(() => {
    auction.photos && handleCarImages(auction.photos);
  }, [auction]);

  const getOneListingBid = () => {
    if (params.list_id) {
      let apiUrl;
      const limit = 10,
        offset = 0;
      if (role !== "user") {
        apiUrl = Services.Listing.GetBidHistoryOfListingForAdmin(
          params.list_id,
          limit,
          offset
        );
      } else {
        apiUrl = Services.Listing.GetBidHistoryOfUserListings(
          params.list_id,
          limit,
          offset
        );
      }
      apiUrl.then((res) => {
        if (res.status) {
          setTotalBids(res.total);
        }
      });
    }
  };

  useEffect(() => {
    getOneListingBid();
  }, [params.list_id]);


  return (
    <>
      {loading && <PreLoaderComponent2 />}
      {!loading && auction && Object.keys(auction)?.length > 0 ? (
        <>
          <div className="leading-normal pb-1 justify-between items-center flex ">
            <div>
              <h2 className="head font-bold flex justify-start items-center">
                {auction?.year +
                  " " +
                  auction?.make +
                  " " +
                  auction?.model +
                  (auction?.title ? " " + auction?.title : "")}
                {auction?.auction?.status !== "pending" &&
                  auction?.auction?.auction_end && (
                    <button
                      type="button"
                      className="lg:hidden"
                      onClick={showShareModal}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="m-4 h-4 ml-2"
                      >
                        <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                      </svg>
                    </button>
                  )}
              </h2>
              <h3 className="p0 gap-1">
                <p className="bgColor text-white p1  tracking-wide	 px-2.5 py-0.5 uppercase m-0 rounded  inline-block shadow-sm">
                  {auction?.has_reserve_price
                    ? t("user_application_page.8td8n0kfb")
                    : t("user_application_page.yuo1tmq41")}
                </p>{" "}
                {auction?.description}
              </h3>
            </div>
            {auction?.auction?.status !== "pending" &&
              auction?.auction?.auction_end && (
                <button
                  type="button"
                  className=" justify-center items-center hidden lg:flex p0"
                  onClick={showShareModal}
                >
                  {t("user_application_page.gt4a9odbo")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="m-4 h-4 ml-2"
                  >
                    <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                  </svg>
                </button>
              )}
          </div>
          {auction_images?.length > 0 && (
            <ImagesGallery
              setShowCars={(value: any) => {
                setShowCars(!!value);
              }}
              imageArray={auction_images}
              sliderIndex={sliderIndex}
              setSliderIndex={setSliderIndex}
              auction_info={auction}
            />
          )}
          {auction?.auction?.auction_end && (
            <div className="w-full sm:container mx-auto  bg-transparent  m-0 px-0">
              <div className="flex flex-col lg:flex-row py-2  w-full p1 font-semibold gap-2 lg:gap-0 my-4">
                <div
                  className={`flex ${
                    auction?.auction?.status !== "live" &&
                    auction?.has_reserve_price
                      ? auction.reserve_price <= auction?.current_bid?.price
                        ? "bg-[#043807]"
                        : "bg-[#560606]"
                      : "bg-black"
                  }  textGray px-4 lg:px-0 lg:pl-4 gap-2 w-full lg:gap-4 py-2 rounded lg:rounded-r-none lg:rounded-l lg:rounded-r-0 justify-between lg:justify-around items-center `}
                >
                  {auction?.auction?.status !== "pending" && (
                    <div className=" flex gap-1 sm:gap-2 justify-center items-center ">
                      {!isAuctionEnded && (
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-3.5 h-3.5 sm:w-4 sm:h-4"
                          >
                            <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                          </svg>
                        </span>
                      )}
                      {!isAuctionEnded && (
                        <p className="hidden sm:block">
                          {t("user_application_page.t9r0a6ldz")}
                        </p>
                      )}
                      <span className="text-white">
                        {auction?.auction?.auction_end &&
                        Utils.CalculateDays(auction?.auction?.auction_end) >
                          0 ? (
                          Utils.CalculateDays(auction?.auction?.auction_end) >
                          1 ? (
                            Utils.CalculateDays(
                              auction?.auction?.auction_end
                            )?.toFixed(0) +
                            " " +
                            (Utils.CalculateDays(
                              auction?.auction?.auction_end
                            ) > 1
                              ? t("user_application_page.64ic2t62l")
                              : t("user_application_page.bm6gea7i7"))
                          ) : (
                            <Countdown date={auction?.auction?.auction_end} daysInHours={true} />
                          )
                        ) : (
                          moment(auction?.auction?.auction_end).format(
                            "MM/DD/YYYY"
                          )
                        )}
                      </span>
                    </div>
                  )}

                  <div className=" flex gap-1 sm:gap-2 justify-center items-center ">
                    {!isAuctionEnded && (
                      <span className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-4 h-4"
                        >
                          <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                        </svg>
                      </span>
                    )}
                    <p className="hidden sm:block">
                      {!isAuctionEnded && auction?.auction?.status === "live"
                        ? t("auction_details_page.auction_status.highest_bid")
                        : auction?.auction?.status === "sold"
                          ? t("auction_details_page.auction_status.sold")
                          : t("auction_details_page.auction_status.bid")}
                    </p>
                    <span className="text-white">
                      {" "}
                      {auction?.current_bid?.price
                        ? auction.current_bid.price
                        : 0}{" "}
                      CHF
                    </span>
                  </div>
                </div>
                <div
                  className={`flex ${
                    auction?.auction?.status !== "live" &&
                    auction?.has_reserve_price
                      ? auction.reserve_price <= auction?.current_bid?.price
                        ? "bg-[#043807]"
                        : "bg-[#560606]"
                      : "bg-black"
                  } textGray  px-4 lg:px-0 lg:pr-4 gap-2 w-full lg:gap-4 py-2  rounded lg:rounded-l-none lg:rounded-r justify-between lg:justify-around items-center`}
                >
                  <div className="flex gap-1 sm:gap-2 justify-center items-center ">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4"
                      >
                        <path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
                      </svg>
                    </span>
                    <p className="hidden sm:block">
                      {t("user_application_page.05bhx8pwk")}
                    </p>
                    <span className="text-white">{totalBids || 0}</span>
                  </div>
                  <div className="flex gap-1 sm:gap-2 justify-center items-center ">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4"
                      >
                        <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" />
                      </svg>
                    </span>
                    <p className="hidden sm:block">
                      {t("user_application_page.2pfl9rej3")}
                    </p>
                    <span className="text-white">
                      {data?.totalComments || 0}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`w-full ${
                  role !== "user" && auctionEnded ? "" : "hidden"
                } py-2 pt-5 px-2 sm:px-1`}
              >
                <Button
                  color="success"
                  fullWidth
                  sx={{ textTransform: "none", padding: 0 }}
                  onClick={() => setShowContact(true)}
                >
                  <Alert
                    severity="success"
                    icon={
                      <ViewInArIcon
                        fontSize="inherit"
                        sx={{
                          color: "var(--dark-text-color)",
                        }}
                      />
                    }
                    className="border border-[var(--success-color)] w-full"
                  >
                    {t("user_application_page.99u9nedt8")}{" "}
                    {auction?.current_bid
                      ? t("user_application_page.id7jehvkd")
                      : t("user_application_page.6myvdec9v")}{" "}
                    .
                  </Alert>
                </Button>
              </div>
            </div>
          )}
          <div className="w-full hidden sm:flex justify-between items-center gap-2">
            <div className="flex justify-between items-end content-center w-full h-full pt-3">
              <div className="flex">
                <div className="w-1/2 border p-1.5 rounded-lg shadow	">
                  <img
                    src={Images.carFax}
                    alt="car fax"
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-end items-end">
                {auction?.auction?.auction_end && (
                  <p className="textGray p1">
                    {t("user_application_page.9bzrio5af")}{" "}
                    {auction?.auction?.views_count}{" "}
                  </p>
                )}
                {auction?.auction?.auction_end && (
                  <p className="textGray p1">
                    {auction?.auction?.status === "pending"
                      ? t("user_application_page.ke3afthzy")
                      : auction?.auction?.status === "live"
                        ? t("user_application_page.ke3afthzv")
                        : auction?.auction?.status === "sold"
                          ? t("user_application_page.ke3aftbzy")
                          : t("user_application_page.ke3afthzd")}{" "}
                    {auction?.auction?.auction_end &&
                      moment(auction.auction.auction_end).format("MMMM DD") +
                        " " +
                        t("user_application_page.h9532m247") +
                        " " +
                        moment(auction.auction.auction_end).format("HH:MM A")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex py-2">
            <PageComponents.DetailedFactsInfo data={auction} />
          </div>
          {role !== "user" && auction.owner_is_dealer && (
            <div className="flex flex-col py-2">
              <h3 className="font-bold head tracking-wide pb-0">
                {t("user_application_page.7290i8ztn")}
              </h3>
              <PageComponents.DealerFactsInfo data={auction} />
            </div>
          )}
          {/* Author Quote */}
          {auction.review && <PageComponents.AuthorQuote data={auction} />}
          <AuctionDetail
            heading={t("user_application_page.fnvbpnh84")}
            html={auction.highlights}
          />
          {/* Equipment */}
          <AuctionDetail
            heading={t("user_application_page.67usrdq62")}
            html={auction.equipment}
          />
          {/* Modification */}
          {auction.modifications && (
            <AuctionDetail
              heading={t("user_application_page.oimc81e0g")}
              html={auction.modifications}
            />
          )}
          {/* Notewortyhy_features */}
          {auction.noteworthy_features && (
            <AuctionDetail
              heading={t("noteworthy_features")}
              html={auction.noteworthy_features}
            />
          )}
          {/* Known Flaws */}
          <AuctionDetail
            heading={t("user_application_page.dskpzdbve")}
            html={auction.flaws}
          />
          {/* Recent Service History */}
          <AuctionDetail
            heading={t("user_application_page.o4ow2lcyf")}
            html={auction.service_history}
          />
          {/* Other Items Included in Sale */}
          <AuctionDetail
            heading={t("user_application_page.qcwtxw210")}
            html={auction.other_items}
          />
          {/* Ownership History */}
          <AuctionDetail
            heading={t("user_application_page.b1ap0il3d")}
            html={auction.ownership_history}
          />
          {/* Seller Notes */}
          {auction.seller_notes && (
            <AuctionDetail
              heading={t("user_application_page.fe9yx67rt")}
              html={auction.seller_notes}
            />
          )}
          {/* Video  */}
          <Suspense fallback={<PreLoaderComponent />}>
            {auction.videos?.length > 0 && (
              <CarVideos videoUrls={auction.videos} />
            )}
          </Suspense>
          {isModalOpen && (
            <ShareModal setIsModalOpen={setIsModalOpen} auctionInfo={auction} />
          )}
          <Suspense fallback={<PreLoaderComponent />}>
            {auction.photos && (
              <CarShowCase
                showCars={showCars}
                setShowCars={setShowCars}
                data={auction.photos}
                sliderIndex={sliderIndex}
                setSliderIndex={setSliderIndex}
              />
            )}
          </Suspense>
          <Suspense>
            <ContactComponent
              data={auction}
              open={showContact}
              setOpen={setShowContact}
              show_all={true}
            />
          </Suspense>
        </>
      ) : (
        <p className="text-left p0">{t("user_application_page.zudr5t9da")}</p>
      )}
    </>
  );
});

export default ApplicationAuctionInfo;
