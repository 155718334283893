import React, { useEffect, useRef, useState, memo } from "react";
import { ThemeProvider, Button, Box, Alert } from "@mui/material";
import Utils from "../../utils/index";
import { Input } from "@mui/joy";
import Autocomplete from "@mui/joy/Autocomplete";
import moment from "moment";
import _ from "lodash";
import { WarningAlert } from "../../components";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Country = {
  label: string;
  value: string;
};

interface FirstStepProps {
  data: any;
  setData: any;
  formik: any;
  props: any;
  setNoOfListings: any;
  noOfListings: number;
  currentListings: any[];
  setCurrentListings: any;
  // countries: Country[]
}

type fileInput = null | any;
type array = any[];

const CarInfoFirstStep: React.FC<FirstStepProps> = memo(
  ({
    props,
    data,
    setData,
    formik,
    setNoOfListings,
    noOfListings,
    currentListings,
    setCurrentListings,
  }) => {
    const { t } = useTranslation();
    const licenseInput = useRef<fileInput>(null);
    const [years, setYears] = useState<array>([]);
    const [saleRanges] = useState<string[]>([
      "Less than 10",
      "10-50",
      "50-100",
      "More than 100",
    ]);

    const genrateYearsList: any = () => {
      let year = 1980;
      let yearList: any[] = [];
      let currentYear = parseInt(moment().format("yyyy"));
      for (let i = currentYear - year; i >= 0; i--) {
        yearList.push({
          label: currentYear.toString(),
          value: currentYear.toString(),
        });
        --currentYear;
      }
      setYears(yearList);
    };

    const touchErrorsFields = () => {
      if (props?.handleNext) {
        formik?.setFieldTouched("fullName", true);
        formik?.setFieldTouched("phone", true);
        if (data.dealerOrPrivateparty === "dealer") {
          formik?.setFieldTouched("additional_buyer_fee", true);
          formik?.setFieldTouched("dealershipName", true);
          formik?.setFieldTouched("dealership_website", true);
          formik?.setFieldTouched("sales_range", true);
          formik?.setFieldTouched("license_photo", true);
        }
        formik?.setFieldTouched("vin", true);
        formik?.setFieldTouched("year", true);
        formik?.setFieldTouched("make", true);
        formik?.setFieldTouched("model", true);
        formik?.setFieldTouched("mileage", true);
        formik?.setFieldTouched("transmission", true);
        formik?.setFieldTouched("features", true);
        !data.vehicle_stock && formik?.setFieldTouched("vehicle_stock", true);
        data.vehicle_stock === "modified" &&
          formik?.setFieldTouched("modification", true, true);
        data.has_flaws && formik?.setFieldTouched("flaws", true);
        !data.country && formik?.setFieldTouched("country", true);
        data.country === "us" && formik?.setFieldTouched("zip_code", true);
        data.country === "ca" &&
          formik?.setFieldTouched("city_and_province", true);
        data.listed_elsewhere &&
          formik?.setFieldTouched("existing_listing", true);
      }
      window.scrollTo(0, 0);
    };

    useEffect(() => touchErrorsFields(), [props?.handleNext]);

    const checkErrorsExist = () => {
      if (formik?.errors && props?.handleNext) {
        let prevListingError = false;
        data.listed_elsewhere &&
          noOfListings > 0 &&
          _.times(noOfListings, (index: number) => {
            if (formik?.errors[`current-listing-${index}`]) {
              prevListingError = true;
            }
          });

        if (
          !data.dealerOrPrivateparty ||
          formik?.errors?.fullName ||
          formik?.errors?.phone ||
          (data.dealerOrPrivateparty === "dealer" &&
            (!data.additional_buyer_fee ||
              formik?.errors?.additional_buyer_fee ||
              !data.dealership_name ||
              formik?.errors?.dealershipName ||
              !data.dealership_website ||
              formik?.errors?.dealership_website ||
              !data.sales_range ||
              formik?.errors?.sales_range ||
              !data.license_photo ||
              formik?.errors?.license_photo)) ||
          formik?.errors?.vin ||
          formik?.errors?.year ||
          formik?.errors?.make ||
          formik?.errors?.model ||
          formik?.errors?.mileage ||
          formik?.errors?.transmission ||
          formik?.errors?.features ||
          !data.vehicle_stock ||
          (data.vehicle_stock === "modified" &&
            (!data.modification || formik?.errors?.modification)) ||
          (data.has_flaws && (!data.flaws || formik?.errors?.flaws)) ||
          !data.country ||
          (data.country === "us" &&
            (!data.zip_code || formik?.errors?.zip_code)) ||
          (data.country === "ca" &&
            (!data.city_and_province || formik?.errors?.city_and_province)) ||
          (data.listed_elsewhere &&
            (!data.current_listings ||
              data.current_listings.length === 0 ||
              formik?.errors?.existing_listing ||
              prevListingError))
        ) {
          props?.setErrMessage(t("submit_page.validation.error_message"));
          props?.handleNext && props?.setHandleNext(false);
        } else {
          props?.setErrMessage("");
          props?.handleNext && props?.setPage(2);
        }
      }
    };

    useEffect(() => {
      setTimeout(() => checkErrorsExist(), 500);
    }, [formik?.errors, props?.handleNext]);

    useEffect(() => {
      genrateYearsList();
    }, []);

    const handleFileInput = (e: any) => {
      formik?.setFieldTouched("license_photo", true, false);
      const file = e?.target?.files[0];
      if (file) {
        if (
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg"
        ) {
          // get size in MBs
          const fileSize = file.size / (1024 * 1024);
          if (fileSize <= 2) {
            const file_url = URL.createObjectURL(file);
            setData((old: any) => {
              return {
                ...old,
                license_preview: file_url,
                license_photo: file,
              };
            });
            formik?.setFieldValue("license_photo", file_url);
            formik?.setFieldError("license_photo", "");
          } else {
            setData((old: any) => {
              return { ...old, license_preview: "", license_photo: null };
            });
            formik?.setFieldError(
              "license_photo",
              t("submit_page.validation.license_photo.size_error_message")
            );
          }
        } else {
          setData((old: any) => {
            return { ...old, license_preview: "", license_photo: null };
          });
          formik?.setFieldError(
            "license_photo",
            t("submit_page.validation.license_photo.invalid_format")
          );
        }
      }

      e.target.value = null;
    };

    const handleChangeValue = (e: any, id: string, index: number) => {
      let listing_values = data.current_listings;
      const listingObject = { id: id, error: "", value: e.target.value.trim() };
      let newListing = [];

      let listingExist = false;

      if (currentListings.length > 0 && listing_values.length > 0) {
        listing_values?.map((item: string, i: number) => {
          if (i === index) {
            listing_values[i] = listingObject.value;
            listingExist = true;
          }
        });
        newListing = currentListings?.map((item: any, i) => {
          if (id === item.list_id) {
            listingExist = true;
            return {
              ...item,
              value: listingObject.value,
              error: listingObject.error,
            };
          }
        });
      }
      if (listingExist) {
        setData((old: any) => {
          return { ...old, current_listings: listing_values };
        });
      } else {
        listing_values.push(listingObject.value);
        setCurrentListings((old: any) => {
          return [...old, listingObject];
        });
      }
    };

    const AddMoreExisitng = () => {
      const element = document.getElementById(
        `current-listing-${noOfListings - 1}`
      ) as HTMLInputElement;
      if (element.value) {
        setNoOfListings(noOfListings + 1);
      } else {
        element.focus();
      }
    };

    useEffect(() => {
      formik?.setFieldTouched("existing_listing", true, false);
      formik?.setFieldValue("existing_listing", data.current_listings);
    }, [data.current_listings]);

    useEffect(() => {
      if (data?.listed_elsewhere && formik?.values?.existing_listing === 0) {
        formik?.setFieldTouched("existing_listing", true, false);
        formik?.setFieldError(
          "existing_listing",
          t("submit_page.validation.existing_listing.required_message2")
        );
      } else {
        formik?.setFieldTouched("existing_listing", true, false);
        formik?.setFieldError("existing_listing", "");
      }
    }, [data?.listed_elsewhere, formik?.values?.existing_listing]);

    const handleQuillValue = (field: string, text: string) => {
      formik?.setFieldTouched(field, true);
      formik?.setFieldValue(field, text);
      return;
    };  


    return (
      <div className={props?.page === 2 ? "hidden" : ""}>
        {props?.errMessage && (
          <div className="py-4 w-full p1">
            <Alert severity="error">{props?.errMessage}</Alert>
          </div>
        )}
        <div className="CarStep">
          <div className="flex flex-row gap-2">
            <div className="leftStepStyle"></div>
            <h2 className="head font-bold ">
              {t("submit_page.car_first_step_component.heading1")}
            </h2>
          </div>
          <div className="pt-5 flex flex-col flex-wrap">
            <h3 className="p1 px-1">
              {t("submit_page.car_first_step_component.heading2")}
            </h3>
            <div className="flex flex-row gap-4 pt-3 flex-wrap">
              <Box>
                <ThemeProvider
                  theme={
                    data?.dealerOrPrivateparty === "dealer"
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, dealerOrPrivateparty: "dealer" };
                      });
                    }}
                  >
                    {t("submit_page.car_first_step_component.dealer_title")}
                  </Button>
                </ThemeProvider>
              </Box>
              <Box>
                <ThemeProvider
                  theme={
                    data?.dealerOrPrivateparty === "private party"
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return {
                          ...old,
                          dealerOrPrivateparty: "private party",
                        };
                      });
                    }}
                  >
                    {t(
                      "submit_page.car_first_step_component.private_party_title"
                    )}
                  </Button>
                </ThemeProvider>
              </Box>
            </div>
          </div>
          {/* for private party */}
          {data?.dealerOrPrivateparty === "private party" && (
            <div className="pt-5 flex flex-col">
              <div className="flex flex-col lg:flex-row gap-4 pt-3 ">
                <div className="flex-col w-full lg:w-1/2 space-y-1">
                  <label className="p1 px-1" htmlFor="fullName">
                    {t("submit_page.car_first_step_component.full_name_label")}
                  </label>
                  <Input
                    name="fullName"
                    variant="outlined"
                    required={true}
                    value={data?.name}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(
                        Utils.RegExp.nonTextPattern,
                        ""
                      );
                      formik?.handleChange(e);
                      setData((old: any) => {
                        return {
                          ...old,
                          name: e.target.value,
                        };
                      });
                    }}
                    sx={
                      formik?.errors?.fullName && formik?.touched?.fullName
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                    slotProps={{
                      input: {
                        maxLength: 20,
                      },
                    }}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.fullName ? formik?.errors?.fullName : ""
                    }
                  />
                </div>
                <div className="flex-col w-full lg:w-1/2 space-y-1">
                  <label className="p1 px-1" htmlFor="phone">
                    {t(
                      "submit_page.car_first_step_component.contact_phone_number_label"
                    )}
                  </label>
                  <div className="InputFieldSetting">
                    <Input
                      name="phone"
                      variant="outlined"
                      required={true}
                      type="text"
                      value={formik?.values?.phone}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        formik?.handleChange(e);
                        setData((old: any) => {
                          return {
                            ...old,
                            phone: e.target.value,
                          };
                        });
                      }}
                      sx={
                        formik?.errors?.phone && formik?.touched?.phone
                          ? Utils.Theme.JoyInputError
                          : Utils.Theme.JoyGrayShadeInputStyle2
                      }
                      onBlur={formik?.handleBlur}
                      slotProps={{
                        input: {
                          min: 0,
                        },
                      }}
                    />
                  </div>
                  <WarningAlert
                    message={
                      formik?.touched?.phone ? formik?.errors?.phone : ""
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {/* for dealer */}
          {data.dealerOrPrivateparty === "dealer" && (
            <div className="pt-5 flex flex-col">
              <div className="flex flex-col lg:flex-row gap-4 pt-3">
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1 px-1" htmlFor="buyerFee">
                    {t(
                      "submit_page.car_first_step_component.additional_buyer_fee_label"
                    )}
                  </label>
                  <div className="InputFieldSetting">
                    <Input
                      name="additional_buyer_fee"
                      variant="outlined"
                      required={true}
                      type="text"
                      value={data.additional_buyer_fee}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        formik?.handleChange(e);
                        setData((old: any) => {
                          return {
                            ...old,
                            additional_buyer_fee: e.target.value,
                          };
                        });
                      }}
                      sx={
                        formik?.errors?.additional_buyer_fee &&
                        formik?.touched?.additional_buyer_fee
                          ? Utils.Theme.JoyInputError
                          : Utils.Theme.JoyGrayShadeInputStyle2
                      }
                      onBlur={formik?.handleBlur}
                      slotProps={{
                        input: {
                          min: 0,
                        },
                      }}
                    />
                  </div>
                  <WarningAlert
                    message={
                      formik?.touched?.additional_buyer_fee
                        ? formik?.errors?.additional_buyer_fee
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-4 pt-3">
                <div className="flex-col w-full lg:w-1/2 space-y-1">
                  <label className="p1 px-1" htmlFor="dealershipName">
                    {t(
                      "submit_page.car_first_step_component.dealership_name_label"
                    )}
                  </label>
                  <Input
                    name="dealershipName"
                    variant="outlined"
                    required={true}
                    value={formik?.values?.dealershipName}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(
                        Utils.RegExp.nonTextPattern,
                        ""
                      );
                      formik?.handleChange(e);
                      setData((old: any) => {
                        return {
                          ...old,
                          dealership_name: e.target.value,
                        };
                      });
                    }}
                    sx={
                      formik?.errors?.dealershipName &&
                      formik?.touched?.dealershipName
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.dealershipName
                        ? formik?.errors?.dealershipName
                        : ""
                    }
                  />
                </div>
                <div className="flex-col w-full lg:w-1/2 space-y-1">
                  <label className="p1 px-1" htmlFor="dealership_website">
                    {t(
                      "submit_page.car_first_step_component.dealership_website_label"
                    )}
                  </label>
                  <Input
                    name="dealership_website"
                    variant="outlined"
                    required={true}
                    value={data?.dealership_website}
                    onChange={(e) => {
                      formik?.handleChange(e);
                      setData((old: any) => {
                        return {
                          ...old,
                          dealership_website: e.target.value.trim(),
                        };
                      });
                    }}
                    sx={
                      formik?.errors?.dealership_website &&
                      formik?.touched?.dealership_website
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.dealership_website
                        ? formik?.errors?.dealership_website
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-4 pt-3">
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1  px-1" htmlFor="sales_range">
                    {t(
                      "submit_page.car_first_step_component.dealership_retail"
                    )}
                  </label>
                  <Autocomplete
                    disableClearable={true}
                    name="sales_range"
                    options={saleRanges}
                    value={saleRanges[data.sales_range - 1]}
                    onChange={(e: any, newValue: any) => {
                      formik?.setFieldValue("sales_range", newValue);
                      setData((old: any) => {
                        return {
                          ...old,
                          sales_range:
                            newValue === "Less than 10"
                              ? 1
                              : newValue === "10-50"
                                ? 2
                                : newValue === "50-100"
                                  ? 3
                                  : newValue === "More than 100"
                                    ? 4
                                    : -1,
                        };
                      });
                    }}
                    required={true}
                    placeholder="Select sale range"
                    sx={
                      formik?.errors?.sales_range &&
                      formik?.touched?.sales_range
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.sales_range
                        ? formik?.errors?.sales_range
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-4 pt-3">
                <div className="flex-col w-full lg:w-1/2 space-y-1">
                  <label className="p1 px-1" htmlFor="name">
                    {t("submit_page.car_first_step_component.full_name_label")}
                  </label>
                  <Input
                    name="fullName"
                    variant="outlined"
                    required={true}
                    value={formik?.values?.fullName}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(
                        Utils.RegExp.nonTextPattern,
                        ""
                      );
                      formik?.handleChange(e);
                      setData((old: any) => {
                        return {
                          ...old,
                          name: e.target.value,
                        };
                      });
                    }}
                    sx={
                      formik?.errors?.fullName && formik?.touched?.fullName
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.fullName ? formik?.errors?.fullName : ""
                    }
                  />
                </div>
                <div className="flex-col w-full lg:w-1/2 space-y-1">
                  <label className="p1 px-1" htmlFor="phone">
                    {t(
                      "submit_page.car_first_step_component.contact_phone_number_label"
                    )}
                  </label>
                  <div className="InputFieldSetting">
                    <Input
                      name="phone"
                      variant="outlined"
                      required={true}
                      type="text"
                      value={formik?.values?.phone}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        formik?.handleChange(e);
                        setData((old: any) => {
                          return { ...old, phone: e.target.value };
                        });
                      }}
                      sx={
                        formik?.errors?.fullName && formik?.touched?.fullName
                          ? Utils.Theme.JoyInputError
                          : Utils.Theme.JoyGrayShadeInputStyle2
                      }
                      onBlur={formik?.handleBlur}
                      slotProps={{
                        input: {
                          min: 0,
                        },
                      }}
                    />
                  </div>
                  <WarningAlert
                    message={
                      formik?.touched?.phone ? formik?.errors?.phone : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 pt-3">
                <div className="flex-col w-full space-y-1">
                  <label className="p1 px-1 my-5" htmlFor="licensePhoto">
                    {t(
                      "submit_page.car_first_step_component.license_photo_label"
                    )}
                  </label>
                  <div className="rounded p-5 bg-[var(--mid-gray-color)] border shadow-sm">
                    <p className="p1 ">
                      {t(
                        "submit_page.car_first_step_component.license_photo_p"
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="up-bt-layout">
                    <input
                      type="file"
                      ref={licenseInput}
                      onChange={(e: any) => handleFileInput(e)}
                      hidden
                      name="licensePhoto"
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <ThemeProvider theme={Utils.Theme.TransParentButton}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={(e) => {
                        licenseInput?.current?.click();
                      }}
                    >
                      {t(
                        "submit_page.car_first_step_component.upload_license_butn_label"
                      )}
                    </Button>
                  </ThemeProvider>
                  <WarningAlert
                    message={
                      formik?.touched?.license_photo
                        ? formik?.errors?.license_photo
                        : ""
                    }
                  />
                </div>
                <div
                  className={`${
                    !data.license_preview ? "hidden" : ""
                  } relative`}
                  style={{ width: 175, height: 120 }}
                >
                  <img
                    className="w-full h-full rounded drop-shadow-sm shadow-sm object-cover"
                    src={data?.license_preview}
                    alt="license-image"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="CarStep my-0 ">
          <div className="flex flex-row gap-2">
            <div className="leftStepStyle"></div>
            <h2 className="head font-bold ">
              {t("submit_page.car_first_step_component.heading3")}
            </h2>
          </div>
          <div className="pt-5 flex flex-col">
            <h2 className="p1 px-1">
              {t("submit_page.car_first_step_component.heading4")}
            </h2>
            <div className="flex flex-col lg:flex-row gap-4 mt-3 ">
              <div className="flex-col w-full lg:w-1/2 space-y-1">
                {/* vehicle identification number */}
                <label className="p1 px-1" htmlFor="vin">
                  {t("submit_page.car_first_step_component.vin_label")}
                </label>
                <Input
                  name="vin"
                  variant="outlined"
                  required={true}
                  type="string"
                  value={data?.vin}
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .replace(Utils.RegExp.symbolPattern, "")
                      .trim();
                    formik?.handleChange(e);
                    setData((old: any) => {
                      return {
                        ...old,
                        vin: e.target.value,
                      };
                    });
                  }}
                  sx={
                    formik?.errors?.vin && formik?.touched?.vin
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={formik?.touched?.vin ? formik?.errors?.vin : ""}
                  classes="normal-case"
                />
              </div>
              <div className="flex-col w-full lg:w-1/2 space-y-1">
                <h2 className="p1 px-1">
                  {" "}
                  {t("submit_page.car_first_step_component.year_label")}
                </h2>
                <Autocomplete
                  name="year"
                  disableClearable={true}
                  options={years}
                  required={true}
                  placeholder="Select Year"
                  value={data.year}
                  onChange={(e, newValue) => {
                    setData((old: any) => {
                      return { ...old, year: newValue.value };
                    });
                    formik?.setFieldTouched("year", true, false);
                    formik?.setFieldValue("year", newValue.value);
                  }}
                  sx={
                    formik?.errors?.year && formik?.touched?.year
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={formik?.touched?.year ? formik?.errors?.year : ""}
                />
              </div>
            </div>
          </div>
          <div className="pt-5 flex flex-col">
            <div className="flex flex-col lg:flex-row gap-4 pt-3">
              <div className="flex-col w-full lg:w-1/2 space-y-1">
                <label className="p1 px-1" htmlFor="make">
                  {t("submit_page.car_first_step_component.make_label")}
                </label>
                <Input
                  name="make"
                  variant="outlined"
                  required={true}
                  value={formik?.values?.make}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      Utils.RegExp.textAndDashPattern,
                      ""
                    );
                    formik?.handleChange(e);
                    setData((old: any) => {
                      return {
                        ...old,
                        make: e.target.value,
                      };
                    });
                  }}
                  sx={
                    formik?.errors?.make && formik?.touched?.make
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={formik?.touched?.make ? formik?.errors?.make : ""}
                />
              </div>
              <div className="flex-col w-full lg:w-1/2 space-y-1">
                <label className="p1 px-1" htmlFor="model">
                  {t("submit_page.car_first_step_component.model_label")}
                </label>
                <Input
                  name="model"
                  variant="outlined"
                  required={true}
                  value={formik?.values?.model}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      Utils.RegExp.symbolPattern,
                      ""
                    );
                    formik?.handleChange(e);
                    setData((old: any) => {
                      return {
                        ...old,
                        model: e.target.value,
                      };
                    });
                  }}
                  sx={
                    formik?.errors?.model && formik?.touched?.model
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={formik?.touched?.model ? formik?.errors?.model : ""}
                />
              </div>
            </div>
          </div>
          <div className="pt-5 flex flex-col">
            <div className="flex flex-col lg:flex-row gap-4 pt-3">
              <div className="flex-col lg:w-1/2 space-y-1">
                <label className="p1 px-1" htmlFor="mileage">
                  {t("submit_page.car_first_step_component.mileage_label")}
                </label>
                <div className="InputFieldSetting">
                  <Input
                    type="text"
                    name="mileage"
                    variant="outlined"
                    required={true}
                    value={data?.mileage}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                      formik?.handleChange(e);
                      setData((old: any) => {
                        return { ...old, mileage: e.target.value };
                      });
                    }}
                    sx={
                      formik?.errors?.mileage && formik?.touched?.mileage
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                  />
                </div>
                <WarningAlert
                  message={
                    formik?.touched?.mileage ? formik?.errors?.mileage : ""
                  }
                />
              </div>
              <div className="flex-col lg:w-1/2 space-y-1">
                <label className="p1 px-1" htmlFor="transmission">
                  {t(
                    "submit_page.car_first_step_component.transmission_type_label"
                  )}
                </label>
                <Autocomplete
                  disableClearable={true}
                  name="transmission"
                  placeholder={t(
                    "submit_page.car_first_step_component.transmission_type_placeholder"
                  )}
                  options={[
                    "Automatic /  Automated",
                    "Manual (stick shift with three pedals)",
                  ]}
                  value={data?.transmission}
                  onChange={(e, value) => {
                    setData((old: any) => {
                      return {
                        ...old,
                        transmission:
                          value === "Automatic /  Automated"
                            ? "automatic"
                            : "manual",
                      };
                    });
                    formik?.setFieldTouched("transmission", true, false);
                    formik?.setFieldValue("transmission", value);
                  }}
                  sx={
                    formik?.errors?.transmission &&
                    formik?.touched?.transmission
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={
                    formik?.touched?.transmission
                      ? formik?.errors?.transmission
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="pt-5 flex flex-col ">
            <div className="flex flex-row gap-4 pt-3"></div>
          </div>
          <div className="pt-5 flex flex-col space-y-1">
            <label className="p1 px-1" htmlFor="features">
              {t("submit_page.car_first_step_component.features_label")}
            </label>
            <ReactQuill
              theme="snow"
              placeholder={"Noteworthy options/features"}
              bounds={".app"}
              onChange={(value, delta, source, editor) => {
                setData((old: any) => {
                  return { ...old, features: value };
                });
                setTimeout(() => {
                  handleQuillValue("features", editor.getText());
                }, 5000);
              }}
              value={data.features}
              onBlur={
                (previousSelection, source, editor) =>
                  handleQuillValue("features", editor.getText())
                // handleQuill("features", editor.getLength(), source)
              }
              className={`fadedStyle ${formik?.errors?.features && formik?.touched?.features ? "error" : ""}`}
            />
            <WarningAlert
              message={
                formik?.touched?.features ? formik?.errors?.features : ""
              }
            />
          </div>
          <div className="pt-5 flex flex-col">
            <label className="p1 px-1" htmlFor="carModification">
              {t("submit_page.car_first_step_component.modification_label")}
            </label>
            <div className="flex flex-row gap-3 mt-3">
              <Box>
                <ThemeProvider
                  theme={
                    data?.vehicle_stock === "stock"
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, vehicle_stock: "stock" };
                      });
                      formik?.setFieldTouched("vehicle_stock", true);
                      formik?.setFieldValue("vehicle_stock", "stock");
                    }}
                  >
                    {t(
                      "submit_page.car_first_step_component.complete_stock_butn_text"
                    )}
                  </Button>
                </ThemeProvider>
              </Box>
              <Box>
                <ThemeProvider
                  theme={
                    data?.vehicle_stock === "modified"
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, vehicle_stock: "modified" };
                      });
                      formik?.setFieldTouched("vehicle_stock", true);
                      formik?.setFieldValue("vehicle_stock", "modified");
                    }}
                  >
                    {t(
                      "submit_page.car_first_step_component.modified_butn_text"
                    )}
                  </Button>
                </ThemeProvider>
              </Box>
            </div>
            <WarningAlert
              message={
                formik?.touched?.vehicle_stock
                  ? formik?.errors?.vehicle_stock
                  : ""
              }
            />
            {data.vehicle_stock === "modified" && (
              <div className="">
                <p className="py-3 p1">
                  {t(
                    "submit_page.car_first_step_component.modified_description"
                  )}
                </p>
                <ReactQuill
                  id="modification"
                  theme="snow"
                  placeholder={"Vehicle Modification"}
                  bounds={".app"}
                  onChange={(value, delta, source, editor) => {
                    setData((old: any) => {
                      return { ...old, modification: value };
                    });
                    setTimeout(() => {
                      handleQuillValue("modification", editor.getText());
                    }, 5000);
                  }}
                  value={data.modification}
                  onBlur={(previousSelection, source, editor) =>
                    handleQuillValue("modification", editor.getText())
                  }
                  className={`fadedStyle ${formik?.errors?.modification && formik?.touched?.modification ? "error" : ""}`}
                />
                <WarningAlert
                  message={
                    formik?.touched?.modification
                      ? formik?.errors?.modification
                      : ""
                  }
                />
              </div>
            )}
          </div>
          <div className="pt-5 flex flex-col">
            <label className="p1 px-1" htmlFor="significant">
              {t("submit_page.car_first_step_component.significant_label")}
            </label>
            <div className="flex flex-row gap-4 pt-3" id="significant">
              <Box>
                <ThemeProvider
                  theme={
                    data?.has_flaws
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, has_flaws: true };
                      });
                    }}
                  >
                    {t("submit_page.car_first_step_component.yes")}
                  </Button>
                </ThemeProvider>
              </Box>
              <Box>
                <ThemeProvider
                  theme={
                    !data?.has_flaws
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, has_flaws: false };
                      });
                    }}
                  >
                    {t("submit_page.car_first_step_component.no")}
                  </Button>
                </ThemeProvider>
              </Box>
            </div>
            {data.has_flaws && (
              <div className="">
                <p className="py-3 p1">
                  {t("submit_page.car_first_step_component.flaws_label")}
                </p>
                <ReactQuill
                  id="flaws"
                  theme="snow"
                  placeholder={"Known Flaws"}
                  bounds={".app"}
                  onChange={(value, delta, source, editor) => {
                    setData((old: any) => {
                      return { ...old, flaws: value };
                    });
                    setTimeout(() => {
                      handleQuillValue("flaws", editor.getText());
                    }, 5000);
                  }}
                  value={data.flaws}
                  onBlur={(previousSelection, source, editor) =>
                    handleQuillValue("flaws", editor.getText())
                  }
                  className={`fadedStyle ${formik?.errors?.flaws && formik?.touched?.flaws ? "error" : ""}`}
                />
                <WarningAlert
                  message={formik?.touched?.flaws ? formik?.errors?.flaws : ""}
                />
              </div>
            )}
          </div>
          <div className="pt-5 flex flex-col">
            <h2 className="p1 px-1">
              {t("submit_page.car_first_step_component.car_located_heading")}
            </h2>
            <div className="flex flex-col gap-4 pt-3 w-full">
              <Autocomplete
                name="country"
                disableClearable={true}
                options={props.countries}
                required={true}
                value={
                  props.countries?.find((item: any) => {
                    return item.value === data.country;
                  }) || null // Use null if no match is found
                }
                placeholder="Select Country"
                onChange={(e, newValue: Country) => {
                  setData((old: any) => {
                    return { ...old, country: newValue?.value || "" };
                  });
                  formik?.setFieldTouched("country", true, false);
                  formik?.setFieldValue("country", newValue?.value || "");
                }}
                sx={
                  formik?.errors?.country && formik?.touched?.country
                    ? Utils.Theme.JoyInputError
                    : Utils.Theme.JoyGrayShadeInputStyle2
                }
                onBlur={formik?.handleBlur}
              />
              {/* <Box>
                <ThemeProvider
                  theme={
                    data?.country === "us"
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, country: "us" };
                      });
                      formik?.setFieldTouched("country", true);
                      formik?.setFieldValue("country", "us");
                    }}
                  >
                    {t(
                      "submit_page.car_first_step_component.us_country_butn_text"
                    )}
                  </Button>
                </ThemeProvider>
              </Box>
              <Box>
                <ThemeProvider
                  theme={
                    data?.country === "ca"
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, country: "ca" };
                      });
                      formik?.setFieldTouched("country", true);
                      formik?.setFieldValue("country", "ca");
                    }}
                  >
                    {t(
                      "submit_page.car_first_step_component.ca_country_butn_text"
                    )}
                  </Button>
                </ThemeProvider>
              </Box> */}
            </div>
            <WarningAlert
              message={formik?.touched?.country ? formik?.errors?.country : ""}
            />
            {data.country === "ca" && (
              <div className="py-3">
                <label className="p1" htmlFor="city_and_province">
                  {t(
                    "submit_page.car_first_step_component.city_and_province_label"
                  )}
                </label>
                <Input
                  name="city_and_province"
                  value={formik?.values?.city_and_province}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      Utils.RegExp.nonTextPattern,
                      ""
                    );
                    formik?.handleChange(e);
                    setData((old: any) => {
                      return { ...old, city_and_province: e.target.value };
                    });
                  }}
                  sx={
                    formik?.errors?.city_and_province &&
                    formik?.touched?.city_and_province
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={
                    formik?.touched?.city_and_province
                      ? formik?.errors?.city_and_province
                      : ""
                  }
                />
              </div>
            )}
            {data.country === "us" && (
              <div className="py-3">
                <label className="p1" htmlFor="zip_code">
                  {t("submit_page.car_first_step_component.zip_code_label")}
                </label>
                <div className="InputFieldSetting">
                  <Input
                    name="zip_code"
                    value={formik?.values?.zip_code}
                    type="number"
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                      formik?.handleChange(e);
                      setData((old: any) => {
                        return { ...old, zip_code: e.target.value };
                      });
                    }}
                    sx={
                      formik?.errors?.zip_code && formik?.touched?.zip_code
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik?.handleBlur}
                    slotProps={{
                      input: {
                        min: 0,
                      },
                    }}
                  />
                </div>
                <WarningAlert
                  message={
                    formik?.touched?.zip_code ? formik?.errors?.zip_code : ""
                  }
                />
              </div>
            )}
          </div>
          <div className="pt-5 flex flex-col">
            <h2 className="p1 px-1">
              {t("submit_page.car_first_step_component.car_listed_elsewhere")}
            </h2>
            <div className="flex flex-row gap-4 pt-3">
              <Box>
                <ThemeProvider
                  theme={
                    data?.listed_elsewhere
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, listed_elsewhere: true };
                      });
                    }}
                  >
                    {t("submit_page.car_first_step_component.yes")}
                  </Button>
                </ThemeProvider>
              </Box>
              <Box>
                <ThemeProvider
                  theme={
                    !data?.listed_elsewhere
                      ? Utils.Theme.ButtonActiveTheme
                      : Utils.Theme.TransParentButton
                  }
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setData((old: any) => {
                        return { ...old, listed_elsewhere: false };
                      });
                    }}
                  >
                    {t("submit_page.car_first_step_component.no")}
                  </Button>
                </ThemeProvider>
              </Box>
            </div>
            <WarningAlert
              message={
                formik?.touched?.existing_listing
                  ? formik?.errors?.existing_listing
                  : ""
              }
            />
            {data.listed_elsewhere && (
              <div className="py-3">
                <label className="p1" htmlFor="existing_listing">
                  {t(
                    "submit_page.car_first_step_component.existing_listings_label"
                  )}
                </label>
                {_.times(noOfListings, (index) => {
                  return (
                    <div
                      key={index}
                      className="py-1 flex justify-between gap-2 items-center"
                    >
                      <div className="w-full">
                        <Input
                          id={`current-listing-${index}`}
                          name={`current-listing-${index}`}
                          sx={
                            formik?.errors[`current-listing-${index}`] &&
                            formik?.touched[`current-listing-${index}`]
                              ? Utils.Theme.JoyInputError
                              : Utils.Theme.JoyGrayShadeInputStyle2
                          }
                          value={data.current_listings[index]}
                          onChange={(e: any) => {
                            handleChangeValue(
                              e,
                              `current-listing-${index}`,
                              index
                            );
                            formik?.handleChange(e);
                          }}
                          onBlur={formik?.handleBlur}
                          fullWidth
                        />
                        {/* {formik?.errors[`current-listing-${index}`]} */}
                        <WarningAlert
                          message={
                            formik?.touched[`current-listing-${index}`]
                              ? formik?.errors[`current-listing-${index}`]
                              : // : currentListings[index]?.id ===
                                //     `current-listing-${index}`
                                //   ? currentListings[index]?.error
                                ""
                          }
                        />
                      </div>
                    </div>
                  );
                })}
                <Box className="py-2">
                  <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                    <Button
                      type="button"
                      variant="text"
                      className="hover:underline-offset-1 cursor-pointer"
                      onClick={() => AddMoreExisitng()}
                    >
                      +{" "}
                      {t("submit_page.car_first_step_component.add_more_text")}
                    </Button>
                  </ThemeProvider>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CarInfoFirstStep;
