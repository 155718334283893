import API from "../config/api.config";
import Auth from "./Auth.service";
import Storage from "./Storage.service";

const CreateDeviceToken = (data: any) => {
  return API.post(`/api/user/device-tokens`, data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const DeleteDeviceToken = () => {
  return API.delete(
    `/api/user/device-tokens?device_token=${Storage.getDeviceToken()}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const DeviceToken = {
  CreateDeviceToken,
  DeleteDeviceToken,
};

export default DeviceToken;
