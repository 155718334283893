import API from "../config/api.config";
import Auth from "./Auth.service";

const CreateBid = (data: any) => {
  return API.post(`api/user/listings/bids`, data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const UpvoteBid = (id:number, bid_id:number, data: any) => {
  return API.post(`/api/user/listings/${id}/bids/${bid_id}/upvote`, data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const Bid = {
  CreateBid,
  UpvoteBid
};

export default Bid;
