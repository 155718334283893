import { Suspense, lazy, useEffect, useState } from "react";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import "../../../src/styles.css";
import Pagination from "@mui/material/Pagination";
import PageComponents from "../components";
import Services from "../../services";
import { useTranslation } from "react-i18next";
import { Box, Button, Drawer, ThemeProvider, Grid } from "@mui/material";
import { Sort, Tune } from "@mui/icons-material";
import Theme from "../../utils/Theme.utils";

const AuctionCardComponent = lazy(
  () => import("../components/AuctionCard.component")
);

function Search() {
  let { slug } = useParams();
  let navigate = useNavigate();
  const params = useParams();
  const isUserLogedIn = Services.Auth.IsUserLogedIn();
  const { t } = useTranslation();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(10);
  const [auctionSort, setAuctionSort] = useState<string | null>("ending_soon");
  const [resultSort, setResultSort] = useState<string | null>("recently_ended");
  const [startYear, setStartYear] = useState<number | null>(null);
  const [endYear, setEndYear] = useState<number | null>(null);
  const [transmission, setTransmission] = useState<string | null>("");
  const [search, setSearch] = useState<string | null>("");
  const [bodyStyle, setBodyStyle] = useState<string | null>("");
  const [listings, setListings] = useState<any[]>([]);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [savedSearch, setSavedSearch] = useState<boolean>(false);
  const [resultStartYear, setResultStartYear] = useState<number | null>(null);
  const [resultEndYear, setResultEndYear] = useState<number | null>(null);
  const [resultTransmission, setResultTransmission] = useState<string | null>(
    ""
  );
  const [resultBodyStyle, setResultBodyStyle] = useState<string | null>("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loadAuctions, setLoadAuctions] = useState<boolean>(false);
  const [loadResults, setLoadResults] = useState<boolean>(false);
  // search dialogue open
  const [openSearchDialogue, setOpenSearchDialogue] = useState<boolean>(false);
  const [saveSearchInfo, setSaveSearchInfo] = useState<boolean>(false);
  const [auctionFilters, setAuctionFilters] = useState({
    year: {
      open: false,
      value: [0, 0],
    },
    transmation: {
      open: false,
      value: "",
    },
    bodyStyle: {
      open: false,
      value: "",
    },
  });
  const [resultFilters, setResultFilters] = useState({
    year: {
      open: false,
      value: [0, 0],
    },
    transmation: {
      open: false,
      value: "",
    },
    bodyStyle: {
      open: false,
      value: "",
    },
  });
  const [openAuctionSort, setOpenAuctionSort] = useState(false);
  const [openResultSort, setOpenResultSort] = useState(false);
  const [auctionNotifyMe, setAuctionNotifyMe] = useState<boolean>(true);
  // pre loader
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const toggleAuctionDrawer = (newOpen: boolean) => () => {
    setOpenAuctionSort(newOpen);
  };

  const toggleResultDrawer = (newOpen: boolean) => () => {
    setOpenResultSort(newOpen);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(`/search/${search}`);
    getSearchResults(true);
    return getSearchResults(false);
  };

  const saveSearchAndNotifyMe = (e?: any) => {
    setOpenSearchDialogue(false);
    setIsLoading(true);
    e?.preventDefault();
    const data = {
      search_text: params?.slug?.trim().toLowerCase(),
      setting: {
        name: "when_new_auctions_go_live",
        value: auctionNotifyMe,
      },
    };
    Services.Search.SaveSearch(data)
      .then((res: any) => {
        setSavedSearch(true);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
  };

  const saveSearch = () => {
    setOpenSearchDialogue(true);
    setSaveSearchInfo(true);
  };

  const getSavedSearches = () => {
    Services.Search.GetSearch().then((res) => {
      let isSearchSave = false;
      res?.status &&
        res?.data?.length > 0 &&
        res?.data?.map((item: string) => {
          item === params?.slug?.trim().toLowerCase() && (isSearchSave = true);
        });
      setSavedSearch(isSearchSave);
    });
  };

  const DeleteSavedSearch = () => {
    const data = {
      search_text: params?.slug?.trim(),
    };
    Services.Search.DeleteSearch(data).then((res) => {
      res.status && setSavedSearch(false);
    });
  };

  useEffect(() => {
    isUserLogedIn && getSavedSearches();
  }, [params.slug]);

  const getSearchResults = (currentAuction: boolean) => {
    currentAuction ? setLoadAuctions(true) : setLoadResults(true);
    Services.Listing.GetListings(
      offset,
      limit,
      currentAuction ? "auctions" : "results",
      currentAuction ? auctionSort : resultSort,
      currentAuction ? startYear : resultStartYear,
      currentAuction ? endYear : resultEndYear,
      currentAuction ? transmission : resultTransmission,
      slug,
      currentAuction ? bodyStyle : resultBodyStyle
    )
      .then((res) => {
        currentAuction ? setLoadAuctions(false) : setLoadResults(false);
        if (res.status) {
          currentAuction ? setAuctions(res.data) : setListings(res.data);
        }
      })
      .catch((error) => {
        currentAuction ? setLoadAuctions(false) : setLoadResults(false);
      });
  };

  useEffect(() => {
    getSearchResults(true);
  }, [params, auctionSort, startYear, endYear, transmission, bodyStyle]);

  useEffect(() => {
    getSearchResults(false);
  }, [
    params,
    resultSort,
    resultStartYear,
    resultEndYear,
    resultTransmission,
    resultBodyStyle,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const applyAutionFilters = () => {
    if (auctionFilters.year.open) {
      setStartYear(auctionFilters.year.value[0]);
      setEndYear(auctionFilters.year.value[1]);
    } else {
      setStartYear(null);
      setEndYear(null);
    }
    if (auctionFilters.transmation.open) {
      setTransmission(auctionFilters.transmation.value);
    } else {
      setTransmission(null);
    }
    if (auctionFilters.bodyStyle.open) {
      setBodyStyle(auctionFilters.bodyStyle.value);
    } else {
      setBodyStyle(null);
    }
  };

  const applyResultFilters = () => {
    if (resultFilters.year.open) {
      setResultStartYear(resultFilters.year.value[0]);
      setResultEndYear(resultFilters.year.value[1]);
    } else {
      setResultStartYear(null);
      setResultEndYear(null);
    }
    if (resultFilters.transmation.open) {
      setResultTransmission(resultFilters.transmation.value);
    } else {
      setResultTransmission(null);
    }
    if (resultFilters.bodyStyle.open) {
      setResultBodyStyle(resultFilters.bodyStyle.value);
    } else {
      setResultBodyStyle(null);
    }
  };

  const AuctionSortOptions = () => {
    return (
      <div className="flex flex-col md:flex-row gap-2 flex-wrap">
        <button
          className={`main-outline-button-rounded ${
            auctionSort === "ending_soon" ? "main-active-button" : ""
          }`}
          onClick={() => setAuctionSort("ending_soon")}
        >
          {t("search_page.sort_options.ending_soon")}{" "}
        </button>

        <button
          className={`main-outline-button-rounded ${
            auctionSort === "newly_listed" ? "main-active-button" : ""
          }`}
          onClick={() => setAuctionSort("newly_listed")}
        >
          {t("search_page.sort_options.newly_listed")}{" "}
        </button>

        <button
          className={`main-outline-button-rounded ${
            auctionSort === "no_reserve" ? "main-active-button" : ""
          }`}
          onClick={() => setAuctionSort("no_reserve")}
        >
          {t("search_page.sort_options.no_reserve")}{" "}
        </button>
        <div className="flex">
          <button
            className={`main-outline-button-rounded ${
              auctionSort === "lowest_mileage" ? "main-active-button" : ""
            }`}
            onClick={() => setAuctionSort("lowest_mileage")}
          >
            {t("search_page.sort_options.lowest_mileage")}{" "}
          </button>
        </div>
      </div>
    );
  };

  const ResultSortOptions = () => {
    return (
      <div className="flex flex-col md:flex-row gap-2 flex-wrap">
        <button
          className={`main-outline-button-rounded ${
            resultSort === "recently_ended" ? "main-active-button" : ""
          }`}
          onClick={() => setResultSort("recently_ended")}
        >
          {t("search_page.sort_options.recently_ended")}{" "}
        </button>

        <button
          className={`main-outline-button-rounded ${
            resultSort === "lowest_mileage" ? "main-active-button" : ""
          }`}
          onClick={() => setResultSort("lowest_mileage")}
        >
          {t("search_page.sort_options.lowest_mileage")}{" "}
        </button>

        <button
          className={`main-outline-button-rounded ${
            resultSort === "highest_mileage" ? "main-active-button" : ""
          }`}
          onClick={() => setResultSort("highest_mileage")}
        >
          {t("search_page.sort_options.highest_mileage")}{" "}
        </button>

        <button
          className={`main-outline-button-rounded ${
            resultSort === "lowest_price" ? "main-active-button" : ""
          }`}
          onClick={() => setResultSort("lowest_price")}
        >
          {t("search_page.sort_options.lowest_price")}{" "}
        </button>
        <button
          className={`main-outline-button-rounded ${
            resultSort === "highest_price" ? "main-active-button" : ""
          }`}
          onClick={() => setResultSort("highest_price")}
        >
          {t("search_page.sort_options.highest_price")}{" "}
        </button>
      </div>
    );
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("search_page.title")} />
      <div className="text-gray-600 body-font containerSpacing py-16">
        <div className="">
          <div className="flex flex-col">
            <div className="flex flex-wrap flex-row w-full lg:items-center gap-4 lg:mr-auto  justify-self-center sm:justify-between lg:mt-5 pb-8">
              <h2 className="flex head font-bold break-words">
                {t("search_page.search_label")}: {slug}
              </h2>
              <div className=" items-start">
                <Box className=" w-full items-center text-base ">
                  <ThemeProvider
                    theme={
                      savedSearch
                        ? Utils.Theme.AppleButtonTheme
                        : Utils.Theme.ButtonTheme
                    }
                  >
                    <Button
                      variant={savedSearch ? "contained" : "contained"}
                      onClick={() =>
                        isUserLogedIn
                          ? savedSearch
                            ? DeleteSavedSearch()
                            : saveSearch()
                          : document.getElementById("signin")?.click()
                      }
                    >
                      {savedSearch ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-5 h-5 mr-1 items-center"
                          fill="currentColor"
                          viewBox="0 0 576 512"
                        >
                          <path d="M576 128c0-35.3-28.7-64-64-64H205.3c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7H512c35.3 0 64-28.7 64-64V128zM271 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-5 h-5 mr-1 items-center"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          />
                        </svg>
                      )}
                      {savedSearch
                        ? t("search_page.saved_search")
                        : t("search_page.notify_save_search")}
                    </Button>
                  </ThemeProvider>
                </Box>
              </div>
            </div>
            {/* auctions */}
            <h3 className="lg:flex head font-bold py-3">
              {t("search_page.auctions_heading")} ({auctions?.length})
            </h3>
            {/* auction sort */}
            <div className="flex justify-between flex-wrap gap-2 pb-6">
              <div className="hidden md:flex">
                <AuctionSortOptions />
              </div>
              <div className={` md:hidden `}>
                <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                  <Button
                    variant="contained"
                    onClick={toggleAuctionDrawer(true)}
                    sx={{ textTransform: "capitalize" }}
                  >
                    <Sort className="me-2" /> {t("sort_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
              <Drawer
                open={openAuctionSort}
                onClose={toggleAuctionDrawer(false)}
                className="md:hidden"
              >
                <Box
                  sx={{
                    width: 280,
                  }}
                  role="presentation"
                >
                  <div className="flex flex-col gap-4 py-12 px-6">
                    <div className="font-bold head">
                      <Sort /> <p>{t("sort_title")}</p>
                    </div>
                    <AuctionSortOptions />
                  </div>
                </Box>
              </Drawer>
              <div className="flex-1 flex justify-end">
                <PageComponents.FiltersComponent
                  filters={auctionFilters}
                  setFilters={setAuctionFilters}
                  applyFilters={applyAutionFilters}
                />
              </div>
            </div>
            {/* auction content */}
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              <Grid container spacing={1}>
                {loadAuctions ? (
                  <PageComponents.CardSkeletonElements />
                ) : auctions?.length > 0 ? (
                  auctions?.map((auction, index) => (
                    <Grid
                      item
                      className="px-2-"
                      md={4}
                      sm={6}
                      xs={12}
                      key={index}
                    >
                      <AuctionCardComponent
                        item={auction}
                        showDetailsInProp={false}
                      />
                    </Grid>
                  ))
                ) : (
                  <div className="info_message">
                    {t("search_page.no_auction_found")}
                  </div>
                )}
              </Grid>
            </Suspense>
          </div>
          {/* past listings */}
          <h2 className="lg:flex head font-bold py-2 pt-12">
            {t("search_page.result_heading")} ({listings?.length})
          </h2>
          {/* past listing sort */}
          <div className="flex justify-between flex-wrap gap-2 pb-6">
            <div className="hidden md:flex">
              <ResultSortOptions />
            </div>
            <div className={` md:hidden `}>
              <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                <Button
                  variant="contained"
                  onClick={toggleResultDrawer(true)}
                  sx={{ textTransform: "capitalize" }}
                >
                  <Sort className="me-2" /> {t("sort_butn_text")}
                </Button>
              </ThemeProvider>
            </div>
            <Drawer
              open={openResultSort}
              onClose={toggleResultDrawer(false)}
              className="md:hidden"
            >
              <Box
                sx={{
                  width: 280,
                }}
                role="presentation"
              >
                <div className="flex flex-col gap-4 py-12 px-6">
                  <div className=" font-bold p1 ">
                    <Sort /> <p>{t("sort_title")}</p>
                  </div>
                  <ResultSortOptions />
                </div>
              </Box>
            </Drawer>

            <div className="flex-1 flex justify-end">
              <PageComponents.FiltersComponent
                filters={resultFilters}
                setFilters={setResultFilters}
                applyFilters={applyResultFilters}
              />
            </div>
          </div>
          {/* past auction content */}
          <Suspense fallback={<PageComponents.CardSkeletonElements />}>
            <Grid container spacing={1}>
              {loadResults ? (
                <PageComponents.CardSkeletonElements />
              ) : listings?.length > 0 ? (
                listings?.map((lsit, index) => (
                  <Grid item className="px-2" md={4} sm={6} xs={12} key={index}>
                    <AuctionCardComponent
                      item={lsit}
                      showDetailsInProp={false}
                    />
                  </Grid>
                ))
              ) : (
                <div className="info_message">
                  {t("search_page.no_list_text")}
                </div>
              )}
            </Grid>
          </Suspense>
          <div className="flex justify-center mx-auto mt-10">
            {!loadResults && total > 0 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={Theme.MuiPagenationStyle}
              />
            )}
          </div>
        </div>
      </div>
      <PageComponents.SavedSearch
        open={openSearchDialogue}
        // setOpen={setOpenSearchDialogue}
        auctionNotifyMe={auctionNotifyMe}
        setAuctionNotifyMe={setAuctionNotifyMe}
        handleSubmit={saveSearchAndNotifyMe}
        isLoading={isLoading}
      />
    </>
  );
}

export default Search;
