import { Outlet, useNavigate } from "react-router-dom";
import { NavComponent, FooterComponent } from "../components";
import Services from "../services";
import { useEffect } from "react";

const General = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loginStatus = Services.Auth.IsUserLogedIn();
    if (loginStatus === false) {
      return navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col h-full w-full xl:container mx-auto">
      <NavComponent />
      <Outlet />
      <FooterComponent />
    </div>
  );
};

export default General;
