import { useState, useEffect, memo, lazy, Suspense } from "react";
import { Alert, Avatar, Button, Pagination } from "@mui/material";
import Services from "../../services";
import { useParams } from "react-router-dom";
import { PreLoaderComponent2 } from "../../components";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";

const ContactComponent = lazy(() => import("../components/Contact.component"));

type dataProps = {
  auction_info: any;
};

const ApplicationBids = memo(({ auction_info }: dataProps) => {
  const { t } = useTranslation();
  const role = Services.Storage.getCurrentRole();
  const params = useParams();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [bids, setBids] = useState<any[]>([]);
  const [totalBids, setTotalBids] = useState<number>(0);
  const [showContact, setShowContact] = useState<boolean>(false);
  const [selctedBid, setBid] = useState<any>({});
  const [auctionEnded, setAuctionEnded] = useState<boolean>(false);

  const getOneListingBid = () => {
    if (params.list_id) {
      setLoadingdata(true);
      let apiUrl;
      if (Services.Storage.getCurrentRole() !== "user") {
        apiUrl = Services.Listing.GetBidHistoryOfListingForAdmin(
          params.list_id,
          limit,
          offset
        );
      } else {
        apiUrl = Services.Listing.GetBidHistoryOfUserListings(
          params.list_id,
          limit,
          offset
        );
      }
      apiUrl
        .then((res) => {
          setLoadingdata(false);
          if (res.status) {
            setBids(res.data);
            setTotalBids(res.total);
            setTotal(Math.ceil(res.total / limit));
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
    }
  };

  useEffect(() => {
    getOneListingBid();
  }, [limit, offset, page]);

  useEffect(() => {
    setAuctionEnded(
      auction_info?.auction?.status !== "live"
        ? true
        : Utils.CalculateDays(auction_info?.auction?.auction_end) <= 0
    );
  }, [
    Utils.CalculateDays(auction_info?.auction?.auction_end) > 0,
    auction_info?.auction?.status,
  ]);


  return (
    <>
      {loadingdata && <PreLoaderComponent2 />}
      <div className="py-2 leading-normal">
        {auction_info?.auction?.auction_end && (
          <div className="flex bg-black textGray rounded justify-between items-center px-4 py-2">
            <div className=" flex gap-1 sm:gap-2 justify-center items-center ">
              <span className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                >
                  <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                </svg>
              </span>
              <p className="hidden sm:block p1">
                {t("user_application_page.zpaf139h6")}
              </p>
              <span className="text-white p1">
                {" "}
                {auction_info?.current_bid?.price
                  ? auction_info.current_bid.price
                  : 0}{" "}
                CHF
              </span>
            </div>
            <div className="flex gap-1 sm:gap-2 justify-center items-center ">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                >
                  <path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
                </svg>
              </span>
              <p className="hidden sm:block p1">
                {t("user_application_page.ccdwu6q26")}
              </p>
              <span className="text-white p1">{totalBids}</span>
            </div>
          </div>
        )}
        {auction_info?.auction?.auction_end && (
          <div
            className={`w-full ${
              role !== "user" && auctionEnded ? "" : "hidden"
            } py-2 pt-5 px-2 sm:px-1`}
          >
            <Button
              color="success"
              fullWidth
              sx={{ textTransform: "none", padding: 0 }}
              onClick={() => {
                if (role !== "user") {
                  setShowContact(true);
                  setBid(auction_info?.current_bid);
                }
              }}
            >
              <Alert
                severity="success"
                icon={
                  <ViewInArIcon
                    fontSize="inherit"
                    sx={{
                      color: "var(--dark-text-color)",
                    }}
                  />
                }
                className="border border-[var(--success-color)] w-full p1"
              >
                {t("user_application_page.y5kvzhye8")}{" "}
                {auction_info?.current_bid
                  ? t("user_application_page.fc59px1lw")
                  : t("user_application_page.x17fl6hpu")}{" "}
                .
              </Alert>
            </Button>
          </div>
        )}
        <div className="flex py-3 flex-wrap">
          {!loadingdata && bids?.length > 0 ? (
            bids?.map((bid: any, index: number) => (
              <div className="w-full lg:w-1/2 p-1" key={index}>
                <Button
                  color="inherit"
                  fullWidth
                  sx={{ textTransform: "none", padding: 0 }}
                  onClick={() => {
                    if (role !== "user") {
                      setShowContact(true);
                      setBid(bid);
                    }
                  }}
                >
                  <div className="flex flex-col sm:flex-row border rounded px-4 py-2 justify-center sm:justify-between items-center shadow w-full">
                    <div className="flex flex-col sm:flex-row justify-center sm:justify-start items-center ">
                      <Avatar
                        alt={bid?.bidder?.username}
                        src={
                          bid?.bidder?.avatar &&
                          `${process.env.REACT_APP_HOST_API_KEY}/${bid.bidder.avatar}`
                        }
                        style={{
                          margin: "auto",
                          boxShadow:
                            " var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
                        }}
                      />
                      <div className="flex flex-col px-4 justify-center sm:justify-start  items-center sm:items-start">
                        <h4 className="font-bold capitalize p0">
                          {bid?.bidder?.username}
                        </h4>
                      </div>
                    </div>
                    <p className="font-bold p1">
                      {bid.price ? bid.price : 0} CHF
                    </p>
                  </div>
                </Button>
              </div>
            ))
          ) : (
            <p className="text-center p0">
              {t(
                "user_application_page." +
                  (auction_info?.auction?.auction_end
                    ? "9p7tm2q6v"
                    : "9p7tm2q5v")
              )}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-end items-center">
        {!loadingdata && total > 0 && (
          <Pagination
            count={total}
            hidePrevButton
            hideNextButton
            page={page}
            onChange={(e: any, pageNum) => {
              setPage(pageNum);
              setOffset((pageNum - 1) * limit);
            }}
          />
        )}
      </div>
      <Suspense>
        <ContactComponent
          data={{ ...auction_info, current_bid: selctedBid }}
          open={showContact}
          setOpen={setShowContact}
          onlyBidder={true}
        />
      </Suspense>
    </>
  );
});

export default ApplicationBids;
