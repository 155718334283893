import API from "../config/api.config";
import Auth from "./Auth.service";

const GetDashboardData = () => {
  return API.get(`/api/admin/dashboard`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const Dashboard = {
  GetDashboardData,
};

export default Dashboard;
