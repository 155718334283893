import { createTheme } from "@mui/material";
import { mainFontFamily } from "../layouts/Main.layout";

let fontFamily = mainFontFamily.join(", ") + " !important";

const TableTheme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            fontSize: "1rem",
            fontFamily,
          },
        },
      },
    },
  },
});

const AccordionTheme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            fontSize: "1rem",
            fontFamily,
          },
        },
      },
    },
  },
});

const ButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#faca15",
      light: "#fff",
      dark: "rgb(254 240 138)",
      contrastText: "var(--dark-text-color)",
    },
  },
  typography: {
    button: {
      textTransform: "capitalize",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: "1px",
      fontFamily,
    },
  },
});

const MUItextFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily,
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused, &:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--main-color)", // Change the outline color
                borderWidth: "2px", // Increase the border width
                outline: "none",
              },
            },
          },

          // Customize the root style of the TextField
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--main-color)", // Change the border color
            borderWidth: "2px",
          },
        },
      },
    },
  },
});

const MUItextFieldErrorTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily,
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused, &:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--error-color)", // Change the outline color
                borderWidth: "2px", // Increase the border width
                outline: "none",
              },
            },
          },

          // Customize the root style of the TextField
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--error-color)", // Change the border color
            borderWidth: "2px",
          },
        },
      },
    },
  },
});

const JoyInputStyle = {
  fontSize: "1rem",
  fontFamily,
  borderWidth: "2px",
  borderColor: "var(--main-color)",
  "--Input-focusedInset": "var(--main-color)",
  "--Input-focusedThickness": "2px",
  "--Input-focusedHighlight": "var(--main-color)",
  "&::before": {
    transition: "box-shadow .15s ease-in-out",
  },
  "&:focus-within": {
    borderColor: "var(--main-color)",
  },
  "--Textarea-focusedInset": "var(--main-color)",
  "--Textarea-focusedThickness": "2px",
  "--Textarea-focusedHighlight": "var(--main-color)",
};

const JoyGrayShadeInputStyle = {
  fontSize: "1rem",
  fontFamily,
  maxHeight: "100px",
  borderWidth: "2px",
  borderColor: "var(--mid-gray-color)",
  "--Input-focusedInset": "var(--mid-gray-color)",
  "--Input-focusedThickness": "2px",
  "--Input-focusedHighlight": "var(--mid-gray-color)",
  "&::before": {
    transition: "box-shadow .15s ease-in-out",
  },
  "&:focus-within": {
    borderColor: "var(--mid-gray-color)",
  },
  "--Textarea-focusedInset": "var(--mid-gray-color)",
  "--Textarea-focusedThickness": "2px",
  "--Textarea-focusedHighlight": "var(--mid-gray-color)",
  ".MuiTextarea-textarea": {
    overflow: "auto !important",
  },
  ".MuiTextarea-textarea::-webkit-scrollbar": {
    width: "5px",
  },
};

const JoyGrayShadeInputStyle2 = {
  fontSize: "1rem",
  fontFamily,
  background: "var(--input-bg-faded)",
  maxHeight: "100px",
  borderWidth: "2px",
  borderColor: "var(--mid-gray-color)",
  "--Input-focusedInset": "var(--main-color)",
  "--Input-focusedThickness": "2px",
  "--Input-focusedHighlight": "var(--main-color)",
  "&::before": {
    transition: "box-shadow .15s ease-in-out",
  },
  "&:focus-within": {
    borderColor: "var(--main-color)",
  },
  "--Textarea-focusedInset": "var(--main-color)",
  "--Textarea-focusedThickness": "2px",
  "--Textarea-focusedHighlight": "var(--main-color)",
  ".MuiTextarea-textarea": {
    overflow: "auto !important",
  },
  ".MuiTextarea-textarea::-webkit-scrollbar": {
    width: "5px",
  },
  "&focus": {
    boxShadow: "none",
  },
};

const JoyInputError = {
  fontSize: "1rem",
  fontFamily,
  maxHeight: "100px",
  borderWidth: "2px",
  borderColor: "var(--error-color)",
  "--Input-focusedInset": "var(--error-color)",
  "--Input-focusedThickness": "2px",
  "--Input-focusedHighlight": "var(--error-color)",
  "&::before": {
    transition: "box-shadow .15s ease-in-out",
  },
  "&:focus-within": {
    borderColor: "var(--error-color)",
  },
  "--Textarea-focusedInset": "var(--error-color)",
  "--Textarea-focusedThickness": "2px",
  "--Textarea-focusedHighlight": "var(--error-color)",
  ".MuiTextarea-textarea": {
    overflow: "auto !important",
  },
  ".MuiTextarea-textarea::-webkit-scrollbar": {
    width: "5px",
  },
};

const GoogleButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#ffff",
      light: "#ffff",
      dark: "#ffff",
      contrastText: "var(--dark-text-color)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      fontFamily,
    },
  },
});

const FacebookButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#1877F2",
      light: "#1877F2",
      dark: "#1877F2",
      contrastText: "var(--light-text-color)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "none",
      fontFamily,
    },
  },
});

const AppleButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#000000",
      dark: "#000000",
      contrastText: "var(--light-text-color)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "none",
      fontFamily,
    },
  },
});

const LightButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#ff9e0c33",
      light: "#ff9e0c33",
      dark: "#ff9d0a4d",
      contrastText: "var(--main-color)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "none",
      fontFamily,
    },
  },
});

const TabsStyle = createTheme({
  palette: {
    primary: {
      main: "#ff9900f3",
      light: "#ff9900c1",
      dark: "#ff9900",
      contrastText: "var(--light-text-color)",
    },
  },
  typography: {
    button: {
      textTransform: "capitalize",
      fontWeight: "bolder",
      letterSpacing: "1px",
      fontSize: "1rem",
      fontFamily,
    },
  },
});

const TransParentButton = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      light: "#f6f6f7",
      dark: "#f6f6f7",
      contrastText: "var(--dark-text-color)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      fontFamily,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:disabled": {
            opacity: 0.5, // Set the desired opacity here
          },
        },
      },
    },
  },
});

const MenuButtonTheme = createTheme({
  palette: {
    primary: {
      main: "rgba(99, 115, 129, 0.75)",
      light: "rgba(99, 115, 129, 0.75)",
      dark: "rgba(99, 115, 129, 0.75)",
      contrastText: "rgba(99, 115, 129)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      fontFamily,
    },
  },
});

const MenuActiveButtonTheme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 157, 10, 0.1)",
      light: "rgba(255, 157, 10, 0.1)",
      dark: "rgba(255, 157, 10, 0.15)",
      contrastText: "var(--main-color)",
    },
  },
  typography: {
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      fontFamily,
    },
  },
});

const ButtonActiveTheme = createTheme({
  palette: {
    primary: {
      main: "#8b5cf6",
      light: "#8b5cf6c1",
      dark: "#8b5cf6c1",
      contrastText: "var(--light-text-color)",
    },
  },
  typography: {
    button: {
      textTransform: "capitalize",
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: "1px",
      fontFamily,
    },
  },
});

const JoyModalStyle = {
  display: "flex",
  fontFamily,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--joy-palette-background-backdrop)",
  opacity: 1,
  backdropFilter: "blur(8px)",
  transition: "opacity 400ms ease 0s, backdrop-filter 400ms ease 0s",
  width: "100%",
};

const JoyModalSheetStyle = {
  borderRadius: "md",
  fontFamily,
  px: 3,
  py: 4,
  background: "white",
  boxShadow:
    "inset 0px 12px 16px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
  border: "none",
  outline: "none",
  maxHeight: "90vh",
  overflowY: "auto",
  overflowX: "hidden",
  maxWidth: "90vw",
  width: {
    xs: "90vw",
    md: "80vw",
    lg: "60vw",
    xl: "40vw",
  },
};

const JoyModalCloseStyle = {
  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2) !important",
  borderRadius: "50%",
  bgcolor: "background.body",
  background: "white",
  borderColor: "#f1f1f1 !important",
  opacity: 1,
  fontFamily,
};

const MuiPagenationStyle = {
  fontFamily,
  ".MuiPaginationItem-root.Mui-selected": {
    backgroundColor: "var(--dark-color)",
    color: "white",
  },
};

const Theme = {
  MuiPagenationStyle,
  JoyGrayShadeInputStyle2,
  MUItextFieldErrorTheme,
  MUItextFieldTheme,
  JoyInputError,
  JoyInputStyle,
  MenuButtonTheme,
  MenuActiveButtonTheme,
  LightButtonTheme,
  TabsStyle,
  ButtonTheme,
  ButtonActiveTheme,
  TransParentButton,
  GoogleButtonTheme,
  FacebookButtonTheme,
  AppleButtonTheme,
  JoyGrayShadeInputStyle,
  TableTheme,
  AccordionTheme,
  JoyModalSheetStyle,
  JoyModalCloseStyle,
  JoyModalStyle,
};

export default Theme;
