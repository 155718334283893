import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const CreateSchedule = (data: any) => {
  return ApiConfig.post("/api/admin/schedule/time-slots", data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        return window.location.reload();
      }
    });
};

const UpdateSchedule = (id: number, data: any) => {
  return ApiConfig.put(`/api/admin/schedule/time-slots/${id}`, data).then(
    (res: any) => {
      return res.data;
    }
  ).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const GetSchedules = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/admin/schedule/time-slots?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const DeleteSchedule = (id: string | number) => {
  return ApiConfig.delete(`/api/admin/schedule/time-slots/${id}`).then(
    (res: any) => {
      return res.data;
    }
  ).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const GetTimeSlots = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/listings/schedule/time-slots?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const Schedule = {
  GetTimeSlots,
  CreateSchedule,
  GetSchedules,
  DeleteSchedule,
  UpdateSchedule,
};

export default Schedule;
