import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  CookieBannerComponent,
} from "../components";

let mainFontFamily = ["Signika Negative", "Libre Franklin", "sans-serif"];

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col h-full w-full min-h-screen xl:container mx-auto">
      <NavComponent />
      <div className="my-6">
        <Outlet />
      </div>
      <FooterComponent />
      <CookieBannerComponent />
    </div>
  );
}

export default Main;

export { mainFontFamily };
