import { useEffect, useState, memo } from "react";
import moment from "moment";
import Services from "../../services";
import { Button, ThemeProvider, Avatar, Alert, Tooltip } from "@mui/material";
import Utils from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { AuthPages } from "..";
import { useTranslation } from "react-i18next";

// checked
const AuctionComments = memo(
  ({
    data,
    getComments,
    setCommentResponse,
    total,
    setLoadComments,
    setOffset,
    limit,
  }: any) => {
    const isUserLogedin = Services.Auth.IsUserLogedIn();
    const user = Services.Storage.getCurrentUser();
    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const [comments, setComments] = useState<any[]>([]);
    const [auction, setAuction] = useState<any>({});


    useEffect(() => {
      data?.comments && setComments(data?.comments);
      data?.auction_info && setAuction(data?.auction_info);
    }, [data?.comments, data?.auction_info]);

    const updateUpvote = (e: any, item: any) => {
      const data = {
        value: !item.upvoted,
      };

      const url =
        item?.type === "comment"
          ? Services.Comment.UpvoteComment(item.listing_id, item.id, data)
          : Services.Bid.UpvoteBid(item.listing_id, item.id, data);
      url.then((res: any) => {
        if (res.status) {
          let newComments =
            comments?.length > 0
              ? comments?.map((comment) => {
                  if (item.id == comment.id) {
                    comment.upvoted = data.value;
                    data.value
                      ? (comment.upvotes += Number(comment.upvotes))
                      : (comment.upvotes -= Number(comment.upvotes));
                  }
                  return comment;
                })
              : [];
          setComments(newComments);
        }
      });
    };

    const updateFlagged = (e: any, item: any) => {
      const data = {
        value: !item.flagged,
      };

      Services.Comment.FlgComment(item.listing_id, item.id, data).then(
        (res: any) => {
          if (res.status) {
            let newComments =
              comments?.length > 0
                ? comments?.map((comment) => {
                    if (item.id == comment.id) {
                      comment.flagged = data.value;
                      data.value
                        ? (comment.flags += Number(comment.flags))
                        : (comment.flags -= Number(comment.flags));
                    }
                    return comment;
                  })
                : [];
            setComments(newComments);
          }
        }
      );
    };

    const handleScroll: any = () => {
      const commentElement = document.getElementById("dynamicTextArea");
      if (commentElement) {
        commentElement?.focus();
      }
    };

    const handleAuctionComment = (item: string) => {
      setCommentResponse(item);
      handleScroll();
    };

    return (
      <>
        {comments?.length > 0 ? (
          comments?.map((item: any, index: number) => {
            return item.type === "comment" ? (
              <>
                {/* text comments */}
                <div className="py-2" key={index}>
                  <div className="flex justify-start items-start">
                    <Link to={`/user/${item.user.username}`}>
                      <Avatar
                        src={
                          item.user.avatar &&
                          `${process.env.REACT_APP_HOST_API_KEY}/${item.user.avatar}`
                        }
                        className="cursor-pointer"
                        sx={{ width: 30, height: 30 }}
                        alt={item?.user?.username}
                      />
                    </Link>
                    <div className="flex flex-col justify-start items-start py-1">
                      <div className="flex justify-start items-start ">
                        <Link to={`/user/${item.user.username}`}>
                          <span className="pl-2 pr-0.5 font-medium hover:underline cursor-pointer p1">
                            {item?.user?.username}
                          </span>
                        </Link>
                        <div className="px-2 flex justify-start items-center">
                          {item?.user?.is_registered_bidder && (
                            <>
                              <Tooltip title="Registered Bidder">
                                <svg
                                  className="verified  me-1"
                                  width={17}
                                  height={17}
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-labelledby="v-36GQ5z0zNateCalv"
                                >
                                  <path
                                    d="M6.166 1.286c.952-1.715 3.418-1.715 4.37 0l.425.764.84-.24c1.886-.54 3.63 1.205 3.091 3.09l-.24.841.764.425c1.715.952 1.715 3.418 0 4.37l-.764.425.24.84c.54 1.886-1.205 3.63-3.09 3.091l-.841-.24-.424.764c-.953 1.715-3.419 1.715-4.371 0l-.425-.764-.84.24c-1.886.54-3.63-1.205-3.091-3.09l.24-.841-.764-.424c-1.715-.953-1.715-3.419 0-4.371l.764-.425-.24-.84C1.27 3.015 3.015 1.27 4.9 1.81l.841.24.425-.764z"
                                    fill="var(--main-color)"
                                  />
                                  <path
                                    d="M11.5 6.351l-3.625 4.5L6 9.033"
                                    stroke="var(--dark-text-color)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Tooltip>
                            </>
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            stroke="currentColor"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-2 m-auto"
                          >
                            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                          </svg>
                          <p className="p-0 m-0 ps-0.5 p2">{item?.upvotes}</p>
                          {(item?.user?.is_bidder || item?.user?.is_seller) && (
                            <p className="  font-medium p2 capitalize bgColor text-white px-1 rounded ms-1.5 my-auto py-[0.1rem] shadow-sm">
                              {item?.user?.is_seller
                                ? t(
                                    "auction_details_page.comment_section.auction_comments.seller"
                                  )
                                : item?.user?.is_bidder &&
                                  t(
                                    "auction_details_page.comment_section.auction_comments.bidder"
                                  )}
                            </p>
                          )}
                          <p className="px-1 p2 opacity-40 hidden sm:block">
                            {moment(item.created_at).fromNow()}
                          </p>
                          <p className="px-1 p2 opacity-40  sm:hidden">
                            {moment(item.created_at).fromNow()}
                          </p>
                        </div>
                      </div>
                      {/*  display comment if type is response*/}
                      <p className="p1 py-1 px-2 text-pretty overflow-wrap-anywhere">
                        {item.parent_id && (
                          <span className="textColor font-bold">
                            Re: {item.parent.user.username + " "}
                          </span>
                        )}
                        {item.text}
                      </p>
                      <div className="flex justify-start items-center gap-1.5 sm:gap-3 px-2">
                        <button
                          className={`flex justify-center items-center px-2 border rounded-xl p1 cursor-pointer shadow-sm disabled:opacity-75 ${
                            item.upvoted
                              ? "bgColor borderColor"
                              : "bg-transparent"
                          }`}
                          onClick={(e) =>
                            isUserLogedin
                              ? updateUpvote(e, item)
                              : setOpenLogin(true)
                          }
                          disabled={item?.user?.id == currentUser?.id}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            stroke="currentColor"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-2 m-auto"
                          >
                            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                          </svg>
                          <p className="ps-1 p2">{item.upvotes}</p>
                        </button>
                        {item.user?.username !== user?.username && (
                          <button
                            className={`flex gap-1 items-center justify-start p2 ${
                              item?.user?.id === currentUser?.id
                                ? "opacity-50"
                                : "opacity-80"
                            }  `}
                            onClick={() => {
                              isUserLogedin
                                ? handleAuctionComment(item)
                                : setOpenLogin(true);
                            }}
                            disabled={item?.user?.id == currentUser?.id}
                          >
                            <p>
                              {t(
                                "auction_details_page.comment_section.auction_comments.reply"
                              )}
                            </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              stroke="currentColor"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-3  mt-auto scale-[-1] "
                            >
                              <path d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z" />
                            </svg>
                          </button>
                        )}
                        {item.user?.username !== user?.username &&
                          (item.flagged ? (
                            <button
                              className="flex gap-0.5 sm:gap-1 items-center justify-start p2 text-red-900"
                              onClick={(e) =>
                                isUserLogedin
                                  ? updateFlagged(e, item)
                                  : setOpenLogin(true)
                              }
                            >
                              <p>
                                {t(
                                  "auction_details_page.comment_section.auction_comments.flagged"
                                )}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-2.5 "
                              >
                                <path d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z" />
                              </svg>
                            </button>
                          ) : (
                            <button
                              className={`flex gap-0.5 sm:gap-1 items-center justify-start ${
                                item?.user?.id === currentUser?.id
                                  ? "opacity-50"
                                  : "opacity-80"
                              }  p2`}
                              onClick={(e) =>
                                isUserLogedin
                                  ? updateFlagged(e, item)
                                  : setOpenLogin(true)
                              }
                              disabled={item?.user?.id == currentUser?.id}
                            >
                              <p className="p2">
                                {t(
                                  "auction_details_page.comment_section.auction_comments.flag"
                                )}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-2.5 "
                              >
                                <path d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z" />
                              </svg>
                            </button>
                          ))}
                        {/*  marked flas as inappropriate */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              item.type === "bid" && (
                <>
                  {(auction?.auction?.status === "sold" ||
                    auction?.current_bid?.status === "won") &&
                    item?.user?.id === auction?.current_bid?.bidder?.id && (
                      // auction sold status alert
                      <div className="py-2" key={index}>
                        <Alert
                          icon={false}
                          sx={{
                            background: "rgba(0,0,0,0.1)",
                            color: "#000",
                            ".MuiAlert-message": { minWidth: "100%" },
                          }}
                        >
                          <div className="flex flex-row justify-between items-center min-w-full p1">
                            <p className="font-medium	">
                              {auction?.auction?.status === "past" &&
                                auction?.has_reserve_price &&
                                auction?.current_bid?.price <
                                  auction?.reserve_price &&
                                t(
                                  "auction_details_page.comment_section.reserve_not_met"
                                ) + " "}
                              {auction?.auction?.status === "sold" &&
                                t(
                                  "auction_details_page.comment_section.auction_comments.sold_to"
                                ) + " "}
                              <span className="capitalize">
                                {item?.user?.username}
                              </span>{" "}
                              {auction?.auction?.status !== "sold" &&
                                auction?.current_bid?.status === "won" &&
                                " " +
                                  t(
                                    "auction_details_page.comment_section.auction_comments.has_won"
                                  ) +
                                  " "}
                              {t(
                                "auction_details_page.comment_section.auction_comments.for"
                              )}
                              {item.price} CHF
                            </p>
                            <p className="ms-auto">
                              {moment(item.created_at).fromNow()}
                            </p>
                          </div>
                        </Alert>
                      </div>
                    )}
                  {/* bid comments  */}
                  <div className="py-2" key={index}>
                    <div className="flex justify-start items-start">
                      <Link to={`/user/${item?.user?.username}`}>
                        <Avatar
                          src={
                            item?.user?.avatar &&
                            `${process.env.REACT_APP_HOST_API_KEY}/${item?.user?.avatar}`
                          }
                          className="cursor-pointer"
                          sx={{ width: 30, height: 30 }}
                          alt={item?.user?.username}
                        />
                      </Link>
                      <div className="flex flex-col justify-start items-start py-1">
                        <div className="flex justify-start items-start ">
                          {/* profile username */}
                          <Link to={`/user/${item?.user?.username}`}>
                            <span className="pl-2 pr-0.5 font-medium cursor-pointer hover:underline p1">
                              {item?.user?.username}
                            </span>
                          </Link>
                          <div className="px-2 flex justify-center items-center">
                            {item?.upvotes > 0 && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="w-2 m-auto"
                                >
                                  <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                                </svg>
                                <p className="p-0 m-0 ps-0.5 p2">
                                  {item.upvotes}
                                </p>
                              </>
                            )}
                            {item.user.is_registered_bidder && (
                              <>
                                <Tooltip title="Registered Bidder">
                                  <svg
                                    className="verified mx-0.5"
                                    width={17}
                                    height={17}
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-labelledby="v-36GQ5z0zNateCalv"
                                  >
                                    <path
                                      d="M6.166 1.286c.952-1.715 3.418-1.715 4.37 0l.425.764.84-.24c1.886-.54 3.63 1.205 3.091 3.09l-.24.841.764.425c1.715.952 1.715 3.418 0 4.37l-.764.425.24.84c.54 1.886-1.205 3.63-3.09 3.091l-.841-.24-.424.764c-.953 1.715-3.419 1.715-4.371 0l-.425-.764-.84.24c-1.886.54-3.63-1.205-3.091-3.09l.24-.841-.764-.424c-1.715-.953-1.715-3.419 0-4.371l.764-.425-.24-.84C1.27 3.015 3.015 1.27 4.9 1.81l.841.24.425-.764z"
                                      fill="var(--main-color)"
                                    />
                                    <path
                                      d="M11.5 6.351l-3.625 4.5L6 9.033"
                                      stroke="var(--dark-text-color)"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Tooltip>
                              </>
                            )}
                            {(item?.user?.is_bidder ||
                              item?.user?.is_seller) && (
                              <p className="  font-medium p2 capitalize bgColor text-white px-1 rounded ms-1.5 my-auto py-[0.1rem] shadow-sm">
                                {item?.user?.is_seller
                                  ? t(
                                      "auction_details_page.comment_section.auction_comments.seller"
                                    )
                                  : item?.user?.is_bidder &&
                                    t(
                                      "auction_details_page.comment_section.auction_comments.bidder"
                                    )}
                              </p>
                            )}
                            <p className="px-1 p2 opacity-40 hidden sm:block">
                              {moment(item.created_at).fromNow()}
                            </p>
                            <p className="px-1 p2 opacity-40  sm:hidden">
                              {moment(item.created_at).fromNow()}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-start items-center gap-3 py-1 px-2">
                          <h4 className="bg-black text-white font-medium p1 flex rounded-lg px-3 py-0.5 gap-1">
                            <span className="opacity-40">
                              {t(
                                "auction_details_page.comment_section.auction_comments.bid"
                              )}
                            </span>
                            <span>{item.price} CHF</span>
                          </h4>
                          <button
                            className={`flex p2 px-2 border rounded-xl text-base cursor-pointer shadow-sm disabled:opacity-75 ${
                              item.upvoted
                                ? "bgColor borderColor "
                                : "bg-transparent "
                            }`}
                            onClick={(e) =>
                              isUserLogedin
                                ? updateUpvote(e, item)
                                : setOpenLogin(true)
                            }
                            disabled={item?.user?.id == currentUser?.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              stroke="currentColor"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-2 m-auto"
                            >
                              <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                            </svg>
                            <p className="ps-1 p2">{item.upvotes}</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            );
          })
        ) : (
          <p className="pt-4 p0 text-left w-full">
            {t(
              "user_application_page." +
                (data?.auction_info?.auction?.auction_end
                  ? "2pwiaasa5"
                  : "9p7tm2q5v")
            )}
          </p>
        )}
        {comments?.length > 0 && total > limit && comments?.length < total && (
          <ThemeProvider theme={Utils.Theme.TransParentButton}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "var(--mid-gray-color)",
                "&:disabled": {
                  opacity: 0.5, // Set the desired opacity here
                },
              }}
              onClick={() => {
                setLoadComments(true);
                setOffset(Math.ceil((total - limit) / limit) * limit);
              }}
              disabled={total <= data.length}
            >
              {t(
                "auction_details_page.comment_section.auction_comments.load_more_butn_text"
              )}
            </Button>
          </ThemeProvider>
        )}
        <AuthPages.Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
      </>
    );
  }
);

export default AuctionComments;
