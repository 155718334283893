import { useEffect, useState, lazy, Suspense } from "react";
import { AuctionNavComponent } from "../../components";
import { Grid, Pagination, Button, ThemeProvider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PageComponents from "../components";
import Services from "../../services";
import { AppImages } from "../../assets";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import Theme from "../../utils/Theme.utils";

const AuctionCardComponent = lazy(
  () => import("../components/AuctionCard.component")
);

type listingType = any[];

function Home() {
  const location = useLocation();
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [listings, setListings] = useState<listingType>([]);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<string | null>(
    location.pathname === "/past-auctions" ? "recently_ended" : "ending_soon"
  );
  const [startYear, setStartYear] = useState<number | null>(null);
  const [endYear, setEndYear] = useState<number | null>(null);
  const [transmission, setTransmission] = useState<string | null>("");
  const [bodyStyle, setBodyStyle] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [listingsType, setListingsType] = useState<string>(
    location.pathname === "/past-auctions" ? "results" : "auctions"
  ); // auctions or results
  const [newAuctions, setNewAuctions] = useState<listingType>([]);
  const [search, setSearch] = useState<string | null>("");

  // document.addEventListener("DOMContentLoaded", function () {
  //   // Function to replace menu item content
  //   function replaceMenuItem() {
  //     // Select the li element by class name
  //     var menuItem1 = document.querySelector(".menu-item-1007");
  //     // Check if the element exists
  //     if (menuItem1) {
  //       // Replace the HTML content inside the <li>
  //       menuItem1.innerHTML = `
  //           <a href="#home" class="elementor-item elementor-item-anchor elementor-item-active" aria-current="location"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Home</font></font></a>
  //        `;
  //     }
  //     var menuItem2 = document.querySelector(".menu-item-44");
  //     // Check if the element exists
  //     if (menuItem2) {
  //       // Replace the HTML content inside the <li>
  //       menuItem2.innerHTML = `
  //          <a href="#about" class="elementor-item elementor-item-anchor" aria-current=""><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Über</font></font></a>
  //        `;
  //     }
  //   }

  //   // Function to check and possibly replace the menu item based on inner text
  //   function checkInnerText() {
  //     // Select the div with the class 'gt_selected'
  //     var selectedDiv = document.querySelector(".gt_selected");
  //     // Check if the div exists
  //     if (selectedDiv) {
  //       console.log("selectedDiv", selectedDiv);

  //       // Get the inner text of the <a> element within the selected div
  //       var innerText = selectedDiv.querySelector("a").innerText;
  //       // Log the inner text to the console
  //       console.log(innerText); // Output: "German"

  //       // Check the inner text and replace menu items if necessary
  //       if (
  //         innerText.toLowerCase() === "german" ||
  //         innerText.toLowerCase() === "de"
  //       ) {
  //         replaceMenuItem();
  //       }
  //     }
  //   }

  //   // Initial check on page load
  //   checkInnerText();

  //   // Set up a MutationObserver to watch for changes in the selected div
  //   var selectedDiv = document.querySelector(".gt_selected");
  //   if (selectedDiv) {
  //     var observer = new MutationObserver(function (mutations) {
  //       mutations.forEach(function (mutation) {
  //         // If the text inside the <a> tag changes, check the inner text
  //         if (
  //           mutation.type === "childList" ||
  //           mutation.type === "characterData"
  //         ) {
  //           checkInnerText();
  //         }
  //       });
  //     });

  //     // Configuration for the observer
  //     var config = { childList: true, subtree: true, characterData: true };

  //     // Start observing the target node for configured mutations
  //     observer.observe(selectedDiv, config);
  //   }
  // });

  // window.onload = () => {
  //   // Function to replace menu item content
  //   function replaceMenuItem() {
  //     // Select the li element by class name
  //     var menuItem1 = document.querySelector(".menu-item-1007");
  //     // Check if the element exists
  //     if (menuItem1) {
  //       // console.log('textContent', menuItem1.textContent, menuItem1.innerHTML);
  //       // Replace the HTML content inside the <li>
  //       //  menuItem1.innerHTML = `
  //       //      <a href="#home" class="elementor-item elementor-item-anchor elementor-item-active" aria-current="location"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Home</font></font></a>
  //       //   `;
  //       menuItem1.textContent = "Home";
  //     }
  //     var menuItem2 = document.querySelector(".menu-item-44");
  //     // Check if the element exists
  //     if (menuItem2) {
  //       // Replace the HTML content inside the <li>
  //       menuItem2.innerHTML = `
  //           <a href="#about" class="elementor-item elementor-item-anchor" aria-current=""><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Über</font></font></a>
  //         `;
  //     }
  //   }

  //   // 	remove text from language flag
  //   // Select the element by its class name
  //   var elements = document.querySelectorAll(".gt_selected a");

  //   // Loop through each selected element
  //   elements.forEach(function (element) {
  //     // Remove text nodes only
  //     element.childNodes.forEach(function (node) {
  //       if (node.nodeType === Node.TEXT_NODE) {
  //         if (
  //           node.textContent.trim().toLowerCase() == "german" ||
  //           node.textContent.trim().toLowerCase() == "de"
  //         ) {
  //           replaceMenuItem();
  //         }
  //         node.textContent = ""; // Clear the text content
  //       }
  //     });
  //   });

  //   // 	slider script
  //   const slider = document.getElementById("carinich-header");

  //   const swiperSlide = document.querySelectorAll(
  //     ".elementor-slides > .swiper-slide"
  //   );
  //   if (swiperSlide) {
  //     let activeSlide = 1;
  //     let totalSlide = 1;

  //     const SliderNavColor2 = "rgba(255,255,255, 0.35)";

  //     swiperSlide.forEach((node) => {
  //       //     if(node.classList.contains('swiper-slide-active')){
  //       const ariaLabel = node.getAttribute("aria-label");
  //       if (ariaLabel) {
  //         activeSlide = Number(ariaLabel.split("/")[0]);
  //         totalSlide = Number(ariaLabel.split("/")[1]);
  //       }
  //       node.innerHTML += `<div class="sliderCounter">
  //      <p class="current-slide">${activeSlide < 9 ? "0" + activeSlide : activeSlide}</p>
  //      <p class="horizonatalBar" style=" background: linear-gradient(to right, white ${(activeSlide / totalSlide) * 100}%, ${SliderNavColor2} ${(activeSlide / totalSlide) * 100}%, ${SliderNavColor2} ${((totalSlide - activeSlide) / totalSlide) * 100}%);" />
  //      <p class="last-slide">${totalSlide < 9 ? "0" + totalSlide : totalSlide}</p>
  //      </div>`;

  //       // 	}

  //       const projectSliderTitle = `<div class="elementor-element elementor-element-8da6efe elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list animated fadeIn" data-id="8da6efe" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="icon-list.default">
  //        <div class="elementor-widget-container">
  //      <link rel="stylesheet" href="https://crainich.ch/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css">		<ul class="elementor-icon-list-items">
  //              <li class="elementor-icon-list-item">
  //                      <span class="elementor-icon-list-icon">
  //              <svg aria-hidden="true" class="e-font-icon-svg e-fas-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg>						</span>
  //                    <span class="elementor-icon-list-text sliderTextStyle" >PROJECT P_${activeSlide < 9 ? "0" + activeSlide : activeSlide}</span>
  //                  </li>
  //            </ul>
  //        </div>
  //        </div>`;

  //       // Modify the child div content
  //       const childDiv = node.querySelector(".swiper-slide-contents"); // Replace with the actual class or selector of the child div
  //       if (childDiv) {
  //         const temp = childDiv.innerHTML;
  //         // 			console.log("temp", temp);
  //         childDiv.innerHTML = projectSliderTitle;
  //         childDiv.innerHTML += temp;
  //       }
  //     });
  //   }
  // };

  const getListings = (newlyListed?: true) => {
    setListings([]);
    window.scrollTo(0, 100);
    setLoading(true);
    listingsType &&
      Services.Listing.GetListings(
        offset,
        limit,
        newlyListed ? "auctions" : listingsType,
        newlyListed ? "newly_listed" : sort,
        startYear,
        endYear,
        transmission,
        search,
        bodyStyle
      )
        .then((res) => {
          setLoading(false);
          if (newlyListed) {
            // set new auction
            setNewAuctions(res.data);
          } else {
            // set list of past or current operation
            setListings(res.data);
            setTotal(Math.ceil(res.total / limit));
          }
        })
        .catch((error) => {
          setLoading(false);
        });
  };

  useEffect(() => {
    getListings(true);
  }, []);

  useEffect(() => {
    setTotal(0);
    setListingsType(
      location.pathname !== "/past-auctions" ? "auctions" : "results"
    );
    setSort(
      location.pathname !== "/past-auctions" ? "ending_soon" : "recently_ended"
    );
    setStartYear(null);
    setEndYear(null);
    setTransmission("");
    setBodyStyle("");
    setCurrentPage(1);
  }, [location.pathname]);

  useEffect(() => {
    if (location?.state?.refresh) {
      setCurrentPage(1);
      setOffset(0);
      // Optionally reset refresh to false to avoid infinite re-renders
      const newState = { ...location.state, refresh: false };
      window.history.replaceState(newState, "", location.pathname);
    }
  }, [location?.state?.refresh]);

  useEffect(() => {
    getListings();
  }, [
    currentPage,
    sort,
    startYear,
    endYear,
    bodyStyle,
    transmission,
    listingsType,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageComponents.MetaComponent
        title={"Auto Bids"}
        canonicalUrl={window.location.host}
      />
      <AuctionNavComponent
        setSort={setSort}
        sort={sort}
        setStartYear={setStartYear}
        setEndYear={setEndYear}
        setTransmission={setTransmission}
        setBodyStyle={setBodyStyle}
        startYear={startYear}
        endYear={endYear}
        listingsType={listingsType}
      />
      <section className="text-gray-600 body-font containerSpacing">
        <div className="w-full ">
          <div className="h-full w-full">
            <div className="w-full lg:col-span-3 col-span-full">
              <Suspense fallback={<PageComponents.CardSkeletonElements />}>
                <div className=" text-gray-600 body-font">
                  <div className="flex py-8 lg:py-12 mx-auto-">
                    <div className="flex flex-wrap w-full">
                      <Grid container spacing={2}>
                        {loading ? (
                          <PageComponents.CardSkeletonElements />
                        ) : listings?.length > 0 ? (
                          listings?.map((item, index) => (
                            <Grid
                              item
                              className="px-3-"
                              lg={4}
                              sm={6}
                              xs={12}
                              key={index}
                            >
                              <AuctionCardComponent
                                item={item}
                                showDetailsInProp={false}
                              />
                            </Grid>
                          ))
                        ) : (
                          <p className="info_message">
                            {t("user_application_page.zudr5t9da")}
                          </p>
                        )}
                      </Grid>
                    </div>
                  </div>
                  {!loading && (
                    <div className="flex justify-center items-center py-4">
                      {!loading && listings?.length > 0 && (
                        <Pagination
                          count={total}
                          hidePrevButton
                          hideNextButton
                          page={currentPage}
                          onChange={(e: any, pageNum) => {
                            setCurrentPage(pageNum);
                            setOffset((pageNum - 1) * limit);
                          }}
                          shape="rounded"
                          sx={Theme.MuiPagenationStyle}
                        />
                      )}
                    </div>
                  )}
                </div>
              </Suspense>
            </div>
          </div>
        </div>
      </section>

      <div className="my-8">
        <div
          className="w-full bg-cover bg-center"
          style={{
            height: "32rem",
            backgroundImage: `url('${AppImages.BannerImage}')`,
          }}
        >
          <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
            <div className="text-center md:text-3xl lg:w-3/4 xl:w-3/5 md:w-2/3 w-11/12 space-y-4 flex flex-col gap-1 sm:gap-2 items-center justify-center">
              <h3 className="text-white head7 leading-6 md:leading-7 lg:leading-9">
                {t("home_page.about_section.title")}
              </h3>
              <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                <Link to={"/about"}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    size="large"
                  >
                    {t("home_page.about_section.button_text")}
                  </Button>
                </Link>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 sm:py-12 w-full containerSpacing">
        <div className="flex  gap-2 justify-between items-center mb-6 flex-wrap">
          <h2 className="head9">{t("home_page.new_list_section.title")}</h2>
          <div>
            <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={() => window?.scrollTo(0, 0)}
              >
                {t("home_page.new_list_section.button_text")}
              </Button>
            </ThemeProvider>
          </div>
        </div>
        <Grid container spacing={2}>
          <Suspense fallback={<PageComponents.CardSkeletonElements />}>
            {!loading && newAuctions?.length > 0 ? (
              newAuctions?.map((item, index) => {
                return (
                  index < 3 && (
                    <Grid
                      item
                      className="px-3"
                      lg={4}
                      sm={6}
                      xs={12}
                      key={index}
                    >
                      <AuctionCardComponent
                        getListings={getListings}
                        item={item}
                        showDetailsInProp={false}
                      />
                    </Grid>
                  )
                );
              })
            ) : loading ? (
              <PageComponents.CardSkeletonElements />
            ) : (
              <p className="info_message">
                {t("user_application_page.zudr5t9da")}
              </p>
            )}
          </Suspense>
        </Grid>
      </div>
    </>
  );
}

export default Home;
