import React, { useEffect, useState, memo } from "react";
import { Avatar, Button } from "@mui/material";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../services";
import { PreLoaderComponent2 } from "../../components";
import { AuthPages } from "../";
import VerifiedBidderComponent from "./VerifiedBidder.component";
import Utils from "../../utils";
import PageComponents from ".";
import { useTranslation } from "react-i18next";

// checked
const BiddingInfo = memo(
  ({
    data,
    addToWatchList,
    deleteFromWatchList,
    loading,
    setShowCreditCard,
    auctionEnded,
  }: any) => {
    const navigate = useNavigate();
    const isUserLoged = Services.Auth.IsUserLogedIn();
    const user = Services.Storage.getCurrentUser();
    const { t } = useTranslation();
    const [auction_data, setAuctionData] = useState<any>({});
    const [loadingdata, setLoading] = useState<boolean>(false);
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const [savedSearch, setSavedSearch] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [showContact, setShowContact] = useState<boolean>(false);
    const [auctionNotifyMe, setAuctionNotifyMe] = useState<boolean>(true);
    // pre loader
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openSearchDialogue, setOpenSearchDialogue] =
      useState<boolean>(false);

    const saveSearchAndNotifyMe = (e?: any) => {
      e?.preventDefault();
      setOpenSearchDialogue(false);
      setLoading(true);
      const saveSearchData = {
        search_text: data?.make?.toLowerCase(),
        setting: {
          name: "when_new_auctions_go_live",
          value: auctionNotifyMe,
        },
      };
      Services.Search.SaveSearch(saveSearchData)
        .then((res) => {
          setSavedSearch(true);
          setLoading(false);
          if (res.status) {
            return getSavedSearches();
          }
        })
        .catch((error) => {
          return setLoading(false);
        });
    };

    const getSavedSearches = () => {
      isUserLoged &&
        auction_data?.make &&
        Services.Search.GetSearch().then((res) => {
          let isSearchSave = false;
          res.status &&
            res.data?.length > 0 &&
            res.data?.map((item: string) => {
              item === auction_data?.make?.toLowerCase() &&
                (isSearchSave = true);
            });
          setSavedSearch(isSearchSave);
        });
    };

    useEffect(() => {
      if (data && Object.keys(data).length > 0) {
        setAuctionData(data);
      }
    }, [data]);

    useEffect(() => {
      getSavedSearches();
    }, [auction_data?.make]);

    return (
      <>
        {loadingdata && <PreLoaderComponent2 />}
        {Object.keys(auction_data)?.length > 0 && (
          <div className="py-4" id="biddingInfo">
            <h2 className="head px-4 sm:text-xl md:text-2xl font-bold">
              {auction_data?.year +
                " " +
                auction_data?.make +
                " " +
                auction_data?.model +
                (auction_data?.title ? " " + auction_data?.title : "")}
              {auction_data?.has_reserve_price &&
                " · " + t("auction_details_page.bidding_info_section.reserve")}
            </h2>
            {/* for large screen this section will display*/}
            <div className="py-6 hidden md:block">
              <div className="border rounded">
                {/*   display Bid details */}
                <div className=" flex justify-between items-start gap-2 h-full">
                  <div className="px-4 py-6 brder w-1/2 h-full">
                    <div className="flex justify-start items-center gap-1 lg:gap-2 flex-wrap">
                      <h4 className="p0 font-bold tracking-wide">
                        {auctionEnded
                          ? auction_data?.auction?.status === "sold"
                            ? t(
                                "auction_details_page.bidding_info_section.sold_for"
                              )
                            : (auction_data?.auction?.status === "past" &&
                                auction_data?.current_bid?.price <
                                  auction_data?.reserve_price &&
                                t(
                                  "auction_details_page.comment_section.reserve_not_met"
                                ) + " ") +
                              t(
                                "auction_details_page.bidding_info_section.bids_to"
                              )
                          : t(
                              "auction_details_page.bidding_info_section.title"
                            )}
                      </h4>
                      {auction_data?.current_bid && (
                        <Link
                          to={`/user/${auction_data?.current_bid?.bidder?.username}`}
                        >
                          <Avatar
                            className="cursor-pointer hover:underline m-auto"
                            alt={auction_data?.current_bid?.bidder?.username}
                            src={
                              auction_data?.current_bid?.bidder?.avatar &&
                              `${process.env.REACT_APP_HOST_API_KEY}/${auction_data?.current_bid?.bidder?.avatar}`
                            }
                            sx={{ width: 24, height: 24 }}
                          />
                        </Link>
                      )}
                      <Link
                        to={`/user/${auction_data?.current_bid?.bidder?.username}`}
                      >
                        <p className="font-normal tracking-wide p0 cursor-pointer hover:underline">
                          {auction_data?.current_bid?.bidder?.username}
                          {auction_data?.current_bid?.status === "won" &&
                            " (" +
                              t(
                                "auction_details_page.bidding_info_section.won_status"
                              ) +
                              ")"}
                        </p>
                      </Link>
                    </div>
                    <h3 className="font-bold head2 py-2">
                      {auction_data?.current_bid?.price
                        ? auction_data?.current_bid?.price
                        : 0}{" "}
                      CHF
                    </h3>
                    <div className="flex justify-start items-center ">
                      <p className="p1">
                        {t("auction_details_page.bidding_info_section.as_low")}{" "}
                        <span className="font-medium">
                          211 CHF{" "}
                          {t(
                            "auction_details_page.bidding_info_section.per_mont"
                          )}
                          *
                        </span>
                      </p>
                      <div className="px-1">
                        <Button
                          className="butn-grad-Bg cursor-pointer shadow-sm"
                          sx={{
                            paddingX: "0.5rem",
                            paddingY: "0.1rem",
                            minWidth: "max-content",
                          }}
                        >
                          <p className="textColor normal-case	underline underline-offset-2 p2">
                            {t(
                              "auction_details_page.bidding_info_section.rates_butn_text"
                            )}
                          </p>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-6  w-1/2 h-full">
                    <ul className=" w-full">
                      <li className="flex justify-start items-center">
                        <div className="w-3/12 p0 font-medium">
                          {t(
                            "auction_details_page.bidding_info_section.seller_label"
                          )}
                        </div>
                        <div className="flex items-center gap-2 w-9/12">
                          <Link to={"/user/" + auction_data?.seller?.username}>
                            <div className="flex gap-2">
                              <Avatar
                                src={
                                  auction_data?.seller?.avatar &&
                                  `${process.env.REACT_APP_HOST_API_KEY}/${auction_data?.seller?.avatar}`
                                }
                                alt={auction_data?.seller?.username}
                                sx={{ width: 24, height: 24 }}
                                className="cursor-pointer"
                              />
                              <p className="cursor-pointer p0 hover:underline">
                                {auction_data?.seller?.username}
                              </p>
                            </div>
                          </Link>
                          {!auctionEnded && (
                            <Button
                              className="butn-grad-Bg cursor-pointer shadow-sm"
                              sx={{ paddingX: "0.5rem", paddingY: "0.1rem" }}
                              onClick={() =>
                                isUserLoged
                                  ? user?.is_registered_bidder
                                    ? setShowContact(true)
                                    : setShow(true)
                                  : document.getElementById("signin")?.click()
                              }
                            >
                              <p className="textColor normal-case	underline underline-offset-2 p0">
                                {t(
                                  "auction_details_page.bidding_info_section.contact_butn_text"
                                )}
                              </p>
                            </Button>
                          )}
                        </div>
                      </li>
                      <li className="flex justify-start items-center">
                        <div className="w-3/12 p0 font-medium">
                          {auctionEnded
                            ? t("auction_details_page.ended_text")
                            : t(
                                "auction_details_page.bidding_info_section.ending_text"
                              )}
                        </div>
                        <div className="flex gap-2 w-9/12 p0 justify-start items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-3.5 "
                          >
                            <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
                          </svg>
                          <span className="">
                            {auction_data?.stats?.auction_end &&
                              moment(auction_data?.stats?.auction_end).format(
                                "MMMM DD"
                              ) +
                                " " +
                                t("auction_details_page.at_text") +
                                " " +
                                moment(auction_data?.stats?.auction_end).format(
                                  "HH:MM A"
                                )}
                          </span>
                        </div>
                      </li>
                      <li className="flex justify-start items-center">
                        <div className="w-3/12 p0 font-medium">
                          {t(
                            "auction_details_page.bidding_info_section.bids_text"
                          )}
                        </div>
                        <div className="flex gap-2 w-9/12 p0 justify-start items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-3.5 "
                          >
                            <path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
                          </svg>
                          <span className="">{auction_data?.stats?.bids}</span>
                        </div>
                      </li>
                      <li className="flex justify-start items-center">
                        <div className="w-3/12 p0 font-medium">
                          {t(
                            "auction_details_page.bidding_info_section.views_text"
                          )}
                        </div>
                        <div className="flex gap-2 w-9/12 justify-start items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-3.5 "
                          >
                            <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                          </svg>
                          <span className="p0">
                            {auction_data?.auction?.views}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*   place bid bottom bar */}
                <div
                  className={`border rounded-b ${auctionEnded ? "hidden" : ""}`}
                >
                  <div className="p-3 flex  justify-between items-center gap-2  bg-gray-100 ">
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{
                        background: "white",
                        minWidth: "max-content",
                        "&:disabled": {
                          opacity: "0.65",
                          background: "white",
                        },
                      }}
                      onClick={() =>
                        isUserLoged
                          ? setShowCreditCard(true)
                          : document.getElementById("signin")?.click()
                      }
                      disabled={data?.seller?.email === user?.email}
                    >
                      <span className="textColor normal-case font-bold p1">
                        {t(
                          "auction_details_page.bidding_info_section.place_bid_button_text"
                        )}
                      </span>
                    </Button>
                    <div className="flex flex-wrap justify-start gap-2 md:gap-4 items-center ">
                      <button
                        className="hover:underline cursor-pointer flex gap-1 justify-center items-center"
                        onClick={() =>
                          window.open("/what-is#how-works", "_blank")
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-3.5"
                        >
                          <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                        </svg>
                        <p className="p1">
                          {t(
                            "auction_details_page.bidding_info_section.buying_button_text"
                          )}
                        </p>
                      </button>
                      <button
                        className="hover:underline cursor-pointer flex gap-1 justify-center items-center"
                        onClick={() =>
                          isUserLoged
                            ? data?.watching
                              ? deleteFromWatchList()
                              : addToWatchList()
                            : setOpenLogin(true)
                        }
                        disabled={loading}
                      >
                        {data?.watching ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            fill="currentColor"
                            className="w-3.5 shadow-sm text-[var(--main-color)] fill-[var(--main-color)]"
                          >
                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-3.5"
                            viewBox="0 0 576 512"
                          >
                            <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                          </svg>
                        )}
                        <p className="p1">
                          {data?.watching
                            ? t(
                                "auction_details_page.bidding_info_section.watch_button_text.watching"
                              )
                            : t(
                                "auction_details_page.bidding_info_section.watch_button_text.watch"
                              )}
                        </p>
                      </button>
                      <button
                        className="hover:underline cursor-pointer flex gap-1 justify-center items-center"
                        onClick={() =>
                          isUserLoged
                            ? savedSearch
                              ? navigate(
                                  `/search/${auction_data?.make?.toLowerCase()}`
                                )
                              : setOpenSearchDialogue(true)
                            : setOpenLogin(true)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill={`${
                            savedSearch ? "var(--main-color)" : "currentColor"
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-3.5"
                        >
                          {savedSearch ? (
                            <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                          ) : (
                            <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z" />
                          )}
                        </svg>
                        <p className="p1">
                          {savedSearch
                            ? t(
                                "auction_details_page.bidding_info_section.notification_button_text.saved_notified_text"
                              )
                            : t(
                                "auction_details_page.bidding_info_section.notification_button_text.save_and_notify_me_text"
                              )}{" "}
                          <span className="uppercase">
                            {auction_data?.make}
                          </span>
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  for small screens this section will display */}
            <div className="py-3  md:hidden">
              <div className="bg-gray-100 flex justify-around items-center px-2 py-3 rounded">
                <button
                  className={` flex items-center gap-1 cursor-pointer ${
                    data?.watching ? "" : "displayONHover"
                  }  transition-all dhrOnhover`}
                  onClick={() =>
                    isUserLoged
                      ? data?.watching
                        ? deleteFromWatchList()
                        : addToWatchList()
                      : setOpenLogin(true)
                  }
                  disabled={loading}
                >
                  {data?.watching ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      fill="var(--main-color)"
                      className="w-3.5 text-[var(--main-color)] fill-[var(--main-color)]"
                    >
                      <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-3.5"
                      viewBox="0 0 576 512"
                    >
                      <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                    </svg>
                  )}
                  <p className="p1">
                    {data?.watching
                      ? t(
                          "auction_details_page.bidding_info_section.watch_button_text.watching"
                        )
                      : t(
                          "auction_details_page.bidding_info_section.watch_button_text.watch"
                        )}
                  </p>
                </button>
                <button
                  className="hover:underline cursor-pointer flex flex-nowrap gap-0.5 sm:gap-2 justify-center items-center text-sm "
                  onClick={() =>
                    isUserLoged
                      ? setOpenSearchDialogue(true)
                      : setOpenLogin(true)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill={`${
                      savedSearch ? "var(--main-color)" : "currentColor"
                    }`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-3.5"
                  >
                    {savedSearch ? (
                      <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                    ) : (
                      <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z" />
                    )}
                  </svg>
                  <p className="p1">
                    {savedSearch
                      ? t(
                          "auction_details_page.bidding_info_section.notification_button_text.saved_notified_text"
                        )
                      : t(
                          "auction_details_page.bidding_info_section.notification_button_text.save_and_notify_me_text"
                        )}{" "}
                    <span className="uppercase">{auction_data?.make}</span>
                  </p>
                </button>
              </div>
              <div className="px-4 py-4  w-full h-full border-b">
                <ul className=" w-full flex flex-col gap-1">
                  <li className="flex flex-col sm:flex-row justify-start items-start sm:items-center w-full ">
                    <div className="sm:w-4/12 p1 font-medium">
                      {auctionEnded
                        ? auction_data?.auction?.status === "sold"
                          ? t(
                              "auction_details_page.bidding_info_section.sold_for"
                            )
                          : (auction_data?.auction?.status === "past" &&
                              auction_data?.current_bid?.price <
                                auction_data?.reserve_price &&
                              t(
                                "auction_details_page.comment_section.reserve_not_met"
                              ) + " ") +
                            t(
                              "auction_details_page.bidding_info_section.bids_to"
                            )
                        : t(
                            "auction_details_page.bidding_info_section.current_bid"
                          )}
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2 sm:w-8/12 items-start sm:items-center">
                      <p className="font-bold p1">
                        {auction_data?.current_bid?.price
                          ? auction_data?.current_bid?.price
                          : 0}{" "}
                        CHF
                      </p>
                      <Link
                        to={`/user/${auction_data?.current_bid?.bidder?.username}`}
                      >
                        <div className="flex gap-2 sm:w-8/12 items-start sm:items-center justify-center sm:justify-start">
                          <Avatar
                            src={
                              auction_data.current_bid?.bidder?.avatar &&
                              `${process.env.REACT_APP_HOST_API_KEY}/${auction_data.current_bid?.bidder?.avatar}`
                            }
                            alt={auction_data?.current_bid?.bidder?.username}
                            sx={{ width: 24, height: 24 }}
                            className="cursor-pointer m-auto"
                          />

                          <p className="cursor-pointer hover:underline p1">
                            {auction_data?.current_bid?.bidder?.username}
                            {auction_data?.current_bid?.status === "won" &&
                              " (" +
                                t(
                                  "auction_details_page.bidding_info_section.won_status"
                                ) +
                                ")"}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li className="flex justify-start items-center">
                    <div className="w-4/12 p1 font-medium">
                      {t(
                        "auction_details_page.bidding_info_section.seller_label"
                      )}
                    </div>
                    <div className="flex gap-2 w-8/12  items-center">
                      {auction_data?.seller && (
                        <Link to={`/user/${auction_data.seller?.username}`}>
                          <Avatar
                            className="cursor-pointer hover:underline"
                            alt={auction_data?.seller?.username}
                            src={
                              auction_data?.seller?.avatar &&
                              `${process.env.REACT_APP_HOST_API_KEY}/${auction_data?.seller?.avatar}`
                            }
                            sx={{ width: 24, height: 24 }}
                          />
                        </Link>
                      )}
                      <Link to={`/user/${auction_data.seller?.username}`}>
                        <p className="font-normal tracking-wide p1 cursor-pointer hover:underline">
                          {auction_data?.seller?.username}
                        </p>
                      </Link>
                      {!auctionEnded && (
                        <Button
                          className="butn-grad-Bg cursor-pointer shadow"
                          sx={{ paddingX: "0.5rem", paddingY: "0.1rem" }}
                          onClick={() =>
                            user?.is_registered_bidder
                              ? setShowContact(true)
                              : setShow(true)
                          }
                        >
                          <p className="textColor normal-case	underline underline-offset-2 p1">
                            {t(
                              "auction_details_page.bidding_info_section.contact_butn_text"
                            )}
                          </p>
                        </Button>
                      )}
                    </div>
                  </li>
                  <li className="flex justify-start items-center">
                    <div className="w-4/12  p1 font-medium">
                      {auctionEnded
                        ? t("auction_details_page.ended_text")
                        : t(
                            "auction_details_page.bidding_info_section.ending_text"
                          )}
                    </div>
                    <div className="flex gap-2 w-8/12  justify-start items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-3.5 "
                      >
                        <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
                      </svg>
                      <span className="p1">
                        {auction_data?.stats?.auction_end &&
                          moment(auction_data?.stats?.auction_end).format(
                            "MMMM DD"
                          ) +
                            " " +
                            t("auction_details_page.at_text") +
                            " " +
                            moment(auction_data?.stats?.auction_end).format(
                              "HH:MM A"
                            )}
                      </span>
                    </div>
                  </li>
                  <li className="flex justify-start items-center">
                    <div className="w-4/12  p1 font-medium">
                      {t("auction_details_page.bidding_info_section.bids_text")}
                    </div>
                    <div className="flex gap-2 w-8/12 justify-start items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-3.5 "
                      >
                        <path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
                      </svg>
                      <span className="p1">{auction_data?.stats?.bids}</span>
                    </div>
                  </li>
                  <li className="flex justify-start items-center">
                    <div className="w-4/12  p1 font-medium">Views</div>
                    <div className="flex gap-2 w-8/12  justify-start items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-3.5 "
                      >
                        <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                      </svg>
                      <span className="p1">{auction_data?.auction?.views}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="flex justify-start items-center py-4 px-4   gap-2">
                <p className="p0 italic">
                  {t("auction_details_page.bidding_info_section.as_low")}{" "}
                  <span className="font-medium">
                    211 CHF{" "}
                    {t("auction_details_page.bidding_info_section.per_mont")}*
                  </span>
                </p>
                <Button
                  className="butn-grad-Bg cursor-pointer shadow"
                  sx={{
                    paddingX: "0.5rem",
                    paddingY: "0.1rem",
                    minWidth: "max-content",
                  }}
                >
                  <p className="textColor normal-case	underline underline-offset-2 p1">
                    {t(
                      "auction_details_page.bidding_info_section.rates_butn_text"
                    )}
                  </p>
                </Button>
              </div>
            </div>
            <AuthPages.Login
              openLogin={openLogin}
              setOpenLogin={setOpenLogin}
            />
          </div>
        )}
        <VerifiedBidderComponent
          show={show}
          setShow={setShow}
          auction_info={data}
        />
        <PageComponents.AuctionContact
          show={showContact}
          setShow={setShowContact}
          listId={data?.id}
        />
        <PageComponents.SavedSearch
          open={openSearchDialogue}
          auctionNotifyMe={auctionNotifyMe}
          setAuctionNotifyMe={setAuctionNotifyMe}
          handleSubmit={saveSearchAndNotifyMe}
          isLoading={loadingdata}
        />
      </>
    );
  }
);

export default BiddingInfo;
