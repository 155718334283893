import React, { useState, memo } from "react";

interface IProps {
  id: number | String;
  value: Boolean;
  handleChange: any;
}

// checked
const ToggleButton: React.FC<IProps> = memo(({ value, id, handleChange }) => {
  return (
    <div className="flex ">
      <label className="flex items-center relative cursor-pointer select-none">
        <input
          id={id.toString()}
          type="checkbox"
          className="toggleBtn "
          defaultChecked={value.valueOf()}
          onChange={handleChange}
        />
        {value ? (
          <span className="onOffText left-2 text-white p1">On</span>
        ) : (
          <span className="onOffText right-2 text-black p1">Off</span>
        )}
        <span className={`onOffRing ${value ? "right-10" : "left-2"}`} />
      </label>
    </div>
  );
});

export default ToggleButton;
