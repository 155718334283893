import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const GetSearch = () => {
  return ApiConfig.get("/api/user/saved-searches", {}).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const SaveSearch = (data: any) => {
  return ApiConfig.post("/api/user/saved-searches", data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const GetSaveSearchdata = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/saved-searches/data?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const DeleteSearch = (data: any) => {
  return ApiConfig.put("/api/user/saved-searches", data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const Service = {
  GetSearch,
  SaveSearch,
  DeleteSearch,
  GetSaveSearchdata,
};

export default Service;
