import { useState, memo } from "react";
import {
  Button,
  CssBaseline,
  Link,
  Box,
  Typography,
  Container,
  ThemeProvider,
} from "@mui/material";
import Utils from "../../utils";
import { PasswordInputField, WarningAlert } from "../../components";
import Services from "../../services";
import { Input } from "@mui/joy";
import Swal from "sweetalert2";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

const Signin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const Copyright = memo(function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
        sx={{
          fontSize: "1rem",
        }}
      >
        {t("admin_sign_in_page.vtxe09c26")}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="/" target="_blank">
          https://auto-bids.ch
        </Link>
      </Typography>
    );
  });

  const handleSubmit = async () => {
    const data = {
      email: email.trim(),
      password: password.trim(),
    };
    setIsLoading(true);
    await Services.Auth.AdminSignIn(data)
      .then(async (res: any) => {
        if (res.status) {
          await Services.Profile.GetProfileUsingAT(res.data.access_token)
            .then(async (response) => {
              setIsLoading(false);
              if (response.status) {
                await Services.Storage.setSession(
                  res.data.access_token,
                  response.data
                );
                await Services.Storage.setUserRole(res.data.role);
                navigate("/dashboard", { replace: true });
                return window.location.reload();
              }
            })
            .catch((error) => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          Swal.fire(
            t("user_application_page.error"),
            t("user_application_page.unauthorized")
          );
          return navigate("/", { replace: true });
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("admin_sign_in_page.title")} />
      <div className="flex justify-center items-center h-screen	">
        <div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <RouterLink
                to="/"
                className={`flex title-font font-medium  text-gray-900 md:mb-0 decoration-none  justify-center items-center gap-2 py-6`}
              >
                <img
                  src={AppImages.LogoImage}
                  alt="AutoBids Logo"
                  className="w-12 h-auto"
                />
                <h2 className={` capitalize font-bold text-3xl drop-shadow-sm`}>
                  {t("site_title")}
                </h2>
              </RouterLink>
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={async (values) => {
                  await new Promise((resolve) => setTimeout(resolve, 500));
                  handleSubmit();
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email()
                    .trim()
                    .required(t("admin_sign_in_page.7iojxy7b4")),
                  password: Yup.string()
                    .trim()
                    .required(t("admin_sign_in_page.pz8p9k5sd")),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ width: "100%" }}
                      autoComplete=""
                    >
                      <div className="py-4 w-full">
                        <label className="p1 px-1" htmlFor="title">
                          {t("admin_sign_in_page.2bkioglxj")}
                          <sup className="requireField">*</sup>
                        </label>
                        <Input
                          id="email"
                          name="email"
                          variant="outlined"
                          required={true}
                          fullWidth
                          autoComplete="off"
                          autoFocus
                          value={values.email.trim()}
                          onChange={(e) => {
                            handleChange(e);
                            setEmail(e.target.value.trim());
                          }}
                          sx={
                            errors?.email && touched?.email
                              ? Utils.Theme.JoyInputError
                              : Utils.Theme.JoyGrayShadeInputStyle2
                          }
                          onBlur={handleBlur}
                          slotProps={{
                            input: {
                              maxLength: 255,
                            },
                          }}
                        />
                        <WarningAlert
                          message={
                            errors.email && touched.email ? errors.email : ""
                          }
                        />
                      </div>
                      <div className="pb-4 w-full">
                        <PasswordInputField
                          // fieldName is label of the field here
                          fieldName={t("admin_sign_in_page.xbsemat62")}
                          setPassword={setPassword}
                          password={password}
                          required={true}
                          name="password"
                          id="password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          formikProps={props}
                          maxLength={12}
                        />
                        <WarningAlert
                          message={touched?.password ? errors?.password : ""}
                        />
                      </div>

                      <div className="InputFieldSetting pb-4 w-full">
                        <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={(e: any) => handleSubmit(e)}
                            disabled={isSubmitting || isLoading ? true : false}
                          >
                            {t("admin_sign_in_page.sign_in")}
                          </Button>
                        </ThemeProvider>
                      </div>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </Container>
          <Copyright />
        </div>
      </div>
    </>
  );
};

export default Signin;
