import { ApiConfig } from "../config";
import axios from "axios";
import Auth from "./Auth.service";

const SaveCard = (data: any, token?: string | undefined) => {
  if (token) {
    return axios
      .post(
        (process.env.REACT_APP_ENV_MODE === "dev"
          ? process.env.REACT_APP_HOST_API_KEY
          : "/api/public/") + "/api/user/account/cards",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res: any) => {
        return res.data;
      }).catch(async (error: any) => {
        if (error?.response?.status == 401) {
          await Auth.Logout();
          return window.location.reload();
        }
      });
  }
  return ApiConfig.post("/api/user/account/cards", data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const FetchCards = (offset: number, limit: number) => {
  return ApiConfig.get("/api/user/account/cards", {
    params: { offset, limit },
  }).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const getUsersForAdmin = (
  offset: number,
  limit: number,
  search?: string | null
) => {
  return ApiConfig.get(`/api/admin/users`, {
    params: { offset, limit, search },
  }).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const getOneUserForAdmin = (id: number | string) => {
  return ApiConfig.get(`/api/admin/users/${id}`).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const getUserListingsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `/api/admin/users/${id}/listings?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const getUserAuctionsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `api/admin/users/${id}/auctions?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const getUserBidsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `/api/admin/users/${id}/bids?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const getUserCommentsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `/api/admin/users/${id}/comments?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
      return window.location.reload();
    }
  });
};

const User = {
  getUserCommentsForAdmin,
  getUserBidsForAdmin,
  getUserListingsForAdmin,
  SaveCard,
  getUsersForAdmin,
  getOneUserForAdmin,
  getUserAuctionsForAdmin,
  FetchCards,
};

export default User;
