import { useState, useEffect, lazy, Suspense } from "react";
import { ThemeProvider, Button } from "@mui/material";
import Utils from "../../utils";
import PageComponents from "../components";
import Services from "../../services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PreLoaderComponent, PreLoaderComponent2 } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckUserType from "../../utils/checkUserType.utils";
import { AuthPages } from "../";
import { useTranslation } from "react-i18next";

const CarInfoFirstStep = lazy(
  () => import("../components/CarInfoFirstStep.component")
);

const CarInfoSecondStep = lazy(
  () => import("../components/CarInfoSecondStep.component")
);

type formObjType = {
  owner_is_dealer: boolean;
  name: string;
  phone_number: string;
  dealership_fees?: number;
  dealership_name?: string;
  dealership_website?: string;
  dealership_license_photo?: any;
  vin: string;
  year: string;
  make: string;
  model: string;
  mileage: string;
  transmission: string;
  noteworthy_features: string;
  vehicle_stock: string;
  modifications?: string;
  has_flaws: boolean;
  flaws?: string;
  country: string;
  city_and_province?: string;
  zip_code?: string;
  listed_elsewhere: boolean;
  current_listings?: string[];
  title_country: string;
  title_province?: string;
  title_state?: string;
  owner_has_title: boolean;
  title_owner?: string;
  title_status: string;
  has_reserve_price: boolean;
  reserve_price?: string;
  photos: any;
  referral: string | null;
  dealership_monthly_sales?: string;
};

type Country = {
  label: string;
  value: string;
};

export default function Submit() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [listingInfo, setListingInfo] = useState({
    dealerOrPrivateparty: "private party",
    name: "",
    phone: "",
    additional_buyer_fee: 0,
    dealership_name: "",
    dealership_website: "",
    sales_range: "",
    license_photo: {},
    license_preview: "",
    vin: "",
    year: "",
    make: "",
    model: "",
    mileage: "",
    transmission: "",
    features: "",
    vehicle_stock: "",
    modification: "",
    has_flaws: "",
    flaws: "",
    country: "",
    city_and_province: "",
    zip_code: "",
    listed_elsewhere: "",
    current_listings: [],
    title_country: "",
    title_province: "",
    title_state: "",
    owner_has_title: "",
    title_owner: "",
    title_status: "",
    has_reserve_price: "",
    reserve_price: "",
    car_images: [],
    referral: "",
    photo_previews: [],
    title: "",
    Description: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [handleNext, setHandleNext] = useState<boolean>(false);
  const [call, setCall] = useState(0);
  const [noOfListings, setNoOfListings] = useState(1);
  const [currentListings, setCurrentListings] = useState<any[]>([]);
  const [countries, setCountries] = useState<Country[]>([
    {
      label: t("countries.ch"),
      value: "ch",
    },
    {
      label: t("countries.eu"),
      value: "eu",
    },
    {
      label: t("countries.uk"),
      value: "uk",
    },
    {
      label: t("countries.us"),
      value: "us",
    },
    {
      label: t("countries.ca"),
      value: "ca",
    },
    {
      label: t("countries.uae"),
      value: "uae",
    },
  ]);

  function normalizeURL(url: string) {
    // Remove 'www.' and trailing slash, convert to lowercase
    return url
      .replace(/^(https?:\/\/)?(www\.)?/, "")
      .replace(/\/$/, "")
      .toLowerCase();
  }

  const chechDuplication = (
    value: string | undefined | null,
    context: any,
    index: number
  ) => {
    // Normalize the input URL
    let url = (value && normalizeURL(value)) || "";

    // Get the existing listings from context
    let listings = context?.options?.context?.existing_listing || [];

    // If listings exist and the URL is valid
    if (listings.length > 0 && url) {
      // Check if any of the URLs in the listings match the input URL
      const isDuplicate = listings.some((item: string, i: number) => {
        let item_url = (item && normalizeURL(item)) || "";
        return i !== index && item_url === url; // Check only other items (skip the current one)
      });

      // Return the negated result: `true` if no duplicates, `false` if duplicates exist
      return !isDuplicate;
    }

    // If no listings or no URL, consider it as no duplication
    return true;
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phone: "",
      additional_buyer_fee: 0,
      dealershipName: "",
      sales_range: "",
      dealership_website: "",
      vin: "",
      year: "",
      make: "",
      model: "",
      mileage: 0,
      title_country: "",
      transmission: "",
      features: "",
      vehicle_stock: "",
      modification: "",
      flaws: "",
      country: "",
      city_and_province: "",
      zip_code: "",
      existing_listing: [],
      "current-listing-0": "",
      "current-listing-1": "",
      "current-listing-2": "",
      "current-listing-3": "",
      "current-listing-4": "",
      "current-listing-5": "",
      "current-listing-6": "",
      "current-listing-7": "",
      "current-listing-8": "",
      "current-listing-9": "",
      title_province: "",
      title_state: "",
      title_owner: "",
      reserve_price: 0,
      license_photo: "",
      title_status: "",
      car_photos: "",
      referral: "",
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .required(t("submit_page.validation.fullName.required_message"))
        .min(3, t("submit_page.validation.fullName.min_length_message"))
        .max(20, t("submit_page.validation.fullName.max_length_message")),
      phone: Yup.string()
        .trim()
        .required(t("submit_page.validation.phone.required_message"))
        .min(5, t("submit_page.validation.phone.min_length_message"))
        .max(20, t("submit_page.validation.phone.max_length_message")),
      additional_buyer_fee:
        listingInfo.dealerOrPrivateparty == "dealer"
          ? Yup.number()
              .required(
                t(
                  "submit_page.validation.additional_buyer_fee.required_message"
                )
              )
              .min(
                1,
                t(
                  "submit_page.validation.additional_buyer_fee.min_length_message"
                )
              )
              .max(
                100000,
                t(
                  "submit_page.validation.additional_buyer_fee.max_length_message"
                )
              )
          : Yup.number().max(
              20,
              t(
                "submit_page.validation.additional_buyer_fee.max_length_message"
              )
            ),
      dealershipName:
        listingInfo.dealerOrPrivateparty == "dealer"
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.dealershipName.required_message")
              )
              .min(
                3,
                t("submit_page.validation.dealershipName.min_length_message")
              )
              .max(
                20,
                t("submit_page.validation.dealershipName.max_length_message")
              )
          : Yup.string()
              .trim()
              .max(
                20,
                t("submit_page.validation.dealershipName.max_length_message")
              ),
      sales_range:
        listingInfo.dealerOrPrivateparty == "dealer"
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.sales_range.required_message")
              )
          : Yup.string().trim(),
      dealership_website:
        listingInfo.dealerOrPrivateparty == "dealer"
          ? Yup.string()
              .trim()
              .url(
                t(
                  "submit_page.validation.dealership_website.url_format_message"
                )
              )
              .required(
                t("submit_page.validation.dealership_website.required_message")
              )
              .max(
                2000,
                t(
                  "submit_page.validation.dealership_website.max_length_message"
                )
              )
          : Yup.string()
              .trim()
              .url(
                t("submit_page.validation.dealership_website.required_message")
              )
              .max(
                2000,
                t(
                  "submit_page.validation.dealership_website.max_length_message"
                )
              ),
      license_photo:
        listingInfo.dealerOrPrivateparty == "dealer"
          ? Yup.string().required(
              t("submit_page.validation.license_photo.required_message")
            )
          : Yup.string(),
      vin: Yup.string()
        .trim()
        .required(t("submit_page.validation.vin.required_message"))
        .min(8, t("submit_page.validation.vin.min_length_message"))
        .max(17, t("submit_page.validation.vin.max_length_message")),
      year: Yup.string()
        .trim()
        .required(t("submit_page.validation.year.required_message"))
        .length(4, t("submit_page.validation.year.length_message")),
      make: Yup.string()
        .trim()
        .required(t("submit_page.validation.make.required_message"))
        .test(
          "make",
          t("submit_page.validation.make.test_message"),
          (value, context) => value !== context?.parent?.model
        )
        .min(3, t("submit_page.validation.make.min_length_message"))
        .max(30, t("submit_page.validation.make.max_length_message")),
      model: Yup.string()
        .trim()
        .required(t("submit_page.validation.model.required_message"))
        .test(
          "model",
          t("submit_page.validation.model.test_message"),
          (value, context) => value !== context?.parent?.make
        )
        .min(2, t("submit_page.validation.model.min_length_message"))
        .max(30, t("submit_page.validation.model.max_length_message")),
      mileage: Yup.number()
        .required(t("submit_page.validation.mileage.required_message"))
        .min(1, t("submit_page.validation.mileage.min_length_message"))
        .max(3000000, t("submit_page.validation.mileage.max_length_message")),
      transmission: Yup.string()
        .trim()
        .required(t("submit_page.validation.transmission.required_message")),
      features: Yup.string()
        .trim()
        .required(t("submit_page.validation.features.required_message"))
        .min(30, t("submit_page.validation.features.min_length_message"))
        .max(2048, t("submit_page.validation.features.max_length_message")),
      vehicle_stock: Yup.string().required(
        t("submit_page.validation.vehicle_stock.required_message")
      ),
      modification:
        listingInfo.vehicle_stock == "modified"
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.modification.required_message")
              )
              .min(
                5,
                t("submit_page.validation.modification.min_length_message")
              )
              .max(
                2048,
                t("submit_page.validation.modification.max_length_message")
              )
          : Yup.string().max(
              2048,
              t("submit_page.validation.modification.max_length_message")
            ),
      flaws: listingInfo.has_flaws
        ? Yup.string()
            .trim()
            .required(t("submit_page.validation.flaws.required_message"))
            .min(12, t("submit_page.validation.flaws.min_length_message"))
            .max(2048, t("submit_page.validation.flaws.max_length_message"))
        : Yup.string()
            .trim()
            .max(2048, t("submit_page.validation.flaws.max_length_message")),
      country: Yup.string().required(
        t("submit_page.validation.country.required_message")
      ),
      city_and_province:
        listingInfo.country == "ca"
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.city_and_province.required_message")
              )
              .min(
                3,
                t("submit_page.validation.city_and_province.min_length_message")
              )
              .max(
                50,
                t("submit_page.validation.city_and_province.max_length_message")
              )
          : Yup.string()
              .trim()
              .max(
                50,
                t("submit_page.validation.city_and_province.max_length_message")
              ),
      zip_code:
        listingInfo.country == "us"
          ? Yup.string()
              .trim()
              .required(t("submit_page.validation.zip_code.required_message"))
              .min(4, t("submit_page.validation.zip_code.min_length_message"))
              .max(10, t("submit_page.validation.zip_code.max_length_message"))
          : Yup.string()
              .trim()
              .max(10, t("submit_page.validation.zip_code.max_length_message")),
      car_photos: Yup.string().required(
        t("submit_page.validation.car_photos.required_message")
      ),
      existing_listing: listingInfo.listed_elsewhere
        ? Yup.array()
            .required(
              t("submit_page.validation.existing_listing.required_message")
            )
            .min(
              1,
              t("submit_page.validation.existing_listing.min_length_message")
            )
            .max(
              10,
              t("submit_page.validation.existing_listing.max_length_message")
            )
        : Yup.array(),
      "current-listing-0":
        listingInfo?.listed_elsewhere && noOfListings === 1
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-0", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 0)
              )
          : Yup.string(),
      "current-listing-1":
        listingInfo?.listed_elsewhere && noOfListings === 2
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-1", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 1)
              )
          : Yup.string(),
      // Yup.string()
      //   .nullable()
      //   .url(t("submit_page.validation.existing_listing.url_format_message")),
      // "current-listing-2": Yup.string().url(
      //   t("submit_page.validation.existing_listing.url_format_message")
      // )      ,
      "current-listing-2":
        listingInfo?.listed_elsewhere && noOfListings === 3
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-2", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 2)
              )
          : Yup.string(),
      "current-listing-3":
        listingInfo?.listed_elsewhere && noOfListings === 4
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-3", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 3)
              )
          : Yup.string(),
      "current-listing-4":
        listingInfo?.listed_elsewhere && noOfListings === 5
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-4", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 4)
              )
          : Yup.string(),
      "current-listing-5":
        listingInfo?.listed_elsewhere && noOfListings === 6
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-5", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 5)
              )
          : Yup.string(),
      "current-listing-6":
        listingInfo?.listed_elsewhere && noOfListings === 7
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-6", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 6)
              )
          : Yup.string(),
      "current-listing-7":
        listingInfo?.listed_elsewhere && noOfListings === 8
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-7", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 7)
              )
          : Yup.string(),
      "current-listing-8":
        listingInfo?.listed_elsewhere && noOfListings === 9
          ? Yup.string()
              .label("This lisiting")
              .nullable()
              .url(
                t("submit_page.validation.existing_listing.url_format_message")
              )
              .test("current-listing-8", "Duplicate input", (value, context) =>
                chechDuplication(value, context, 8)
              )
          : Yup.string(),
      title_country: Yup.string().required(
        t("submit_page.validation.title_country.required_message")
      ),
      title_province:
        listingInfo.title_country == "ca"
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.title_province.required_message")
              )
              .min(
                3,
                t("submit_page.validation.title_province.min_length_message")
              )
              .max(
                30,
                t("submit_page.validation.title_province.max_length_message")
              )
          : Yup.string().max(
              30,
              t("submit_page.validation.title_province.max_length_message")
            ),
      title_state:
        listingInfo.title_country == "us"
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.title_state.required_message")
              )
              .max(
                30,
                t("submit_page.validation.title_state.max_length_message")
              )
          : Yup.string()
              .trim()
              .max(
                30,
                t("submit_page.validation.title_state.max_length_message")
              ),
      title_owner: listingInfo.owner_has_title
        ? Yup.string()
            .trim()
            .required(t("submit_page.validation.title_owner.required_message"))
            .min(10, t("submit_page.validation.title_owner.min_length_message"))
            .max(
              150,
              t("submit_page.validation.title_owner.max_length_message")
            )
        : Yup.string()
            .trim()
            .max(30, t("submit_page.validation.title_owner.required_message")),
      title_status: Yup.string().required(
        t("submit_page.validation.title_status.required_message")
      ),
      reserve_price: listingInfo.has_reserve_price
        ? Yup.number()
            .required(
              t("submit_page.validation.reserve_price.required_message")
            )
            .min(
              2000,
              t("submit_page.validation.reserve_price.min_length_message")
            )
            .max(
              30000000,
              t("submit_page.validation.reserve_price.max_length_message")
            )
        : Yup.number().max(
            30000000,
            t("submit_page.validation.reserve_price.max_length_message")
          ),
      referral: Yup.string()
        .trim()
        .nullable()
        .notRequired()
        .min(3, t("submit_page.validation.referral.min_length_message"))
        .max(30, t("submit_page.validation.referral.max_length_message")),
    }),
    onSubmit: async (values) => {
      submitHanlder();
    },
  });

  const submitHanlder = () => {
    if (call > 0) {
      return;
    } else {
      setCall(call + 1);
    }

    let errorExist = listingInfo?.photo_previews?.length < 12;
    let countExterior = 0,
      countInterior = 0;
    listingInfo?.photo_previews?.length > 12 &&
      Object.keys(listingInfo?.photo_previews).map((key: any) => {
        let item: any = listingInfo?.photo_previews[key];
        console.log("item", item);
        item?.error && item?.error !== "" && (errorExist = true);
      });

    listingInfo?.car_images?.length > 0 &&
      listingInfo?.car_images?.map((item: any) => {
        if (item?.photoType === "exterior") {
          countExterior++;
        } else if (item?.photoType === "interior") {
          countInterior++;
        }
      });
    (countExterior < 6 || countInterior < 6) && (errorExist = true);
    if (errorExist) {
      return;
    }

    let photos: any = { exterior: [], interior: [] };
    listingInfo?.car_images?.map((item: any) => {
      (item?.photoType == "exterior" || item?.photoType == "interior") &&
        photos[item.photoType].push(item);
    });
    let formObj: formObjType = {
      owner_is_dealer:
        listingInfo.dealerOrPrivateparty == "private party" ? false : true,
      name: listingInfo.name.trim(),
      phone_number: listingInfo.phone,
      vin: listingInfo.vin.trim(),
      year: listingInfo.year,
      make: listingInfo.make.trim(),
      model: listingInfo.model.trim(),
      mileage: listingInfo.mileage,
      transmission: listingInfo.transmission,
      noteworthy_features: listingInfo.features.trim(),
      vehicle_stock: listingInfo.vehicle_stock.trim(),
      has_flaws: listingInfo.has_flaws ? true : false,
      country: listingInfo.country.trim(),
      listed_elsewhere: listingInfo.listed_elsewhere ? true : false,
      title_country: listingInfo.title_country.trim(),
      owner_has_title: listingInfo.owner_has_title ? true : false,
      title_status: listingInfo.title_status.trim(),
      has_reserve_price: listingInfo.has_reserve_price ? true : false,
      photos,
      referral: listingInfo.referral.trim(),
    };
    if (listingInfo.dealerOrPrivateparty == "dealer") {
      formObj.dealership_fees = listingInfo.additional_buyer_fee;
      formObj.dealership_name = listingInfo.dealership_name.trim();
      formObj.dealership_website = listingInfo.dealership_website;
      formObj.dealership_license_photo = listingInfo.license_photo;
      formObj.dealership_monthly_sales = listingInfo.sales_range;
    }
    listingInfo.vehicle_stock == "modified" &&
      (formObj.modifications = listingInfo.modification.trim());
    listingInfo.has_flaws && (formObj.flaws = listingInfo.flaws.trim());
    listingInfo.country == "us" && (formObj.zip_code = listingInfo.zip_code);
    listingInfo.country == "ca" &&
      (formObj.city_and_province = listingInfo.city_and_province.trim());
    listingInfo.listed_elsewhere &&
      (formObj.current_listings = listingInfo.current_listings);
    listingInfo.title_country == "us" &&
      (formObj.title_state = listingInfo.title_state.trim());
    listingInfo.title_country == "ca" &&
      (formObj.title_province = listingInfo.title_province.trim());
    listingInfo.owner_has_title &&
      (formObj.title_owner = listingInfo.title_owner.trim());
    listingInfo.reserve_price &&
      (formObj.reserve_price = listingInfo.reserve_price.trim());
    setLoading(true);

    Services.Listing.AddLisitng(formObj)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          Swal.fire(
            t("submit_page.sweat_alert.success_title"),
            t("submit_page.sweat_alert.success_message")
          );
          return navigate(`/application/${res.data.uuid}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  useEffect(() => {
    if (CheckUserType.isUser_and_loged_in()) {
      CheckUserType.isUser_and_loged_in() == false && navigate("/");
    } else {
      setOpen(true);
    }
  }, []);

  const handleCloseForm = (value: boolean) => {
    // setOpen(!open);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="containerSpacing mx-auto py-16">
      <PageComponents.MetaComponent title={t("submit_page.title")} />
      {loading && <PreLoaderComponent2 />}
      <form onSubmit={(e) => formik?.handleSubmit(e)}>
        <div className=" pb-1 px-3 flex flex-wrap flex-col mx-auto items-start  lg:w-2/3">
          <div className="space-y-5">
            <h2 className="head font-bold">{t("submit_page.heading")}</h2>
            <p className="p1">{t("submit_page.p")}</p>
            <p className="p1">{t("submit_page.p1")}</p>
            <h2 className="p0 font-medium inline-block px-1">
              {t("submit_page.heading1")} {page} {t("submit_page.heading2")} 2
            </h2>
          </div>
          <Suspense fallback={<PreLoaderComponent />}>
            {page == 1 ? (
              <CarInfoFirstStep
                props={{
                  handleNext,
                  setHandleNext,
                  page,
                  setPage,
                  errMessage,
                  setErrMessage,
                  countries,
                }}
                data={listingInfo}
                setData={setListingInfo}
                formik={formik}
                noOfListings={noOfListings}
                setNoOfListings={setNoOfListings}
                currentListings={currentListings}
                setCurrentListings={setCurrentListings}
              />
            ) : (
              <CarInfoSecondStep
                props={{
                  handleNext,
                  setHandleNext,
                  page,
                  setPage,
                  errMessage,
                  setErrMessage,
                  countries,
                }}
                data={listingInfo}
                setData={setListingInfo}
                formik={formik}
              />
            )}
          </Suspense>
        </div>
        <div className=" pb-1 px-3 flex flex-col mx-auto items-start lg:w-2/3 ">
          <div className="flex justify-between items-center gap-2 w-full">
            {page == 2 && (
              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                <Button
                  variant="outlined"
                  onClick={(e: any) => {
                    setPage(1);
                    setHandleNext(false);
                    setErrMessage("");
                  }}
                  type="button"
                  disabled={formik?.isSubmitting}
                >
                  Back
                </Button>
              </ThemeProvider>
            )}
            <ThemeProvider theme={Utils.Theme.ButtonTheme}>
              <Button
                variant="contained"
                onClick={(e: any) => {
                  setHandleNext(true);
                  page == 2 && setCall(0);
                }}
                type={page == 1 ? "button" : "submit"}
                id="submitButn"
                disabled={formik?.isSubmitting}
              >
                {page == 1
                  ? t("submit_page.next_button_text")
                  : t("submit_page.submit_button_text")}
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </form>
      <AuthPages.Login
        openLogin={open}
        setOpenLogin={handleCloseForm}
        onlyCloseOnSubmit={true}
      />
    </div>
  );
}
